.landerFA {
    padding-top:2%;
    padding-left:5%;
    padding-right:5%;
    text-align: center;
}

#ListGroupItemHeader {
    font-weight: bold;
}

#ListGroupItemFeatures {
    border: 0px;
}

#ListGroupItemFeaturesBoldItem {
    font-weight: bold;
    border: 0px;
}

.centered-title {
    text-align: center !important;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    font-family: "Open Sans";
}

.flex-container .list-group .media-left {
    float: left;
    margin: 5px;
}

.flex-container .list-group .list-group-item {
    position: initial;
}


.flex-container > .media {
    flex: 50%;
    padding: 15px 0;
    margin: 0;
    font-family: "Open Sans";
}

.flex-container .media-heading {
    font-weight: 700;
    font-family: "Open Sans";
}

.flex-container h4.media-heading {
    margin-bottom: 15px;
}

.page-container > .page-media {
    padding: 10px 10% 80px;
}

.prodcuts-list > li {
    padding-bottom: 5px;
}

.flex-container > .media-body {
    width: auto;
}

.media-body-top {
    height: 400px;
}

.products-flex-media > .media-body-top > p {
    color: #727272;
}

.products-list > li > p {
    color: #727272;

}

.products-list-header {
    padding-bottom: 5px;
    
}

.products-list {
    padding-left: 15px;
}

.products-media > .media > .media-body {
    padding-bottom: 20px;
}

.products-media > .flex-container > .media {
    max-width: 32.5rem;
    display: flex;
    flex-direction: column;
    /* border: 2px solid #1B539B; */
    border: 2px solid #FF8A00;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    background-color: white;
}

.products-media > .flex-container {
    justify-content: space-between;
}

.page-media p {
    color: #999;
    margin: 0px;
}

.page-media p > .media-left {
    float: left;
}

.page-media p > .media-left > h2 {
    margin: 0;
    padding: 0;
}

.media > .media-body {
    padding-bottom: 50px;
    width: auto;
}

.media-body-bottom {
    /* margin-top: auto; */
    padding-bottom: 0px !important;
}

.page-media > .flex-container > .media > .media-body > p > .media-left > h2 {
    margin: 10px;
    padding: 0;
}

.page-media > .media > .media-body > .media-heading {
    font-weight: 700;
    font-family: "Open Sans";
    margin-bottom: 15px;
}

@media screen and (max-width: 780px) {
    .flex-container {
        flex-direction: column;
    }

    .page-media p > .media-left > h2 {
        margin: 10px;
    }
}