.landerText {
    margin: 0 auto !important;
    text-align: left !important;
    max-width: 800px;
    padding:5%;
}

.title-with-bottom-padding {
    padding-bottom: 20px;
}

.title-with-padding {
    padding: 20px 0;
}