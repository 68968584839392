.App {
  /* margin-top: 15px; */
  width: 100% !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.App .navbar-brand {
  font-weight: bold;
}
.home-container {
    width: 88%;
    margin: auto;
}

.home-top-container {
    background-image: url(/images/imageFlashPicture@2x.png);
    background-size: 150%;
    background-repeat: no-repeat;
    height: 100%;
    min-height: 600px;
    display: flex;
    padding: 128px 10% 0;
    background-position: 100%;
}

.home-top-text-half {
    /*width: 50%;*/
}

.home-top-text-half > h1,
.home-top-text-half > h2,
.home-top-text-half > h3,
.home-top-text-half > h4,
.home-top-text-half > h5,
.home-top-text-half > h6 {
    color: #FFFFFF;
    font-family: "Open Sans";
}

.home-top-text-half > h1 {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0.06px;
    line-height: 46px;
    margin-top: 0;
    margin-bottom: 30px;
}

.home-top-text-half > h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    letter-spacing: 0.37px;
    line-height: 24px;
}

.home-top-text-half > h3 {
    font-size: 21px;
    letter-spacing: -0.11px;
    line-height: 28px;
    margin: 0;
}

.home-top-text-half > h4 {
    font-size: 16px;
    margin: 0;
    letter-spacing: -0.08px;
    line-height: 22px;
}

.home-top-tooltips-container {
    display: flex;
    margin: 50px -5px;
    flex-wrap: wrap;
}

.home-top-tooltips-container > span {
    display: block;
    text-align: center;
    height: 25px;
    width: auto;
    border-radius: 12.5px;
    background-color: #1B539B;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.5);
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.27px;
    line-height: 18px;
    padding: 3px 10px;
    margin: 5px;
    /* cursor: pointer; */
    width: 115px;
}

.home-top-links-container {
    margin-top: 40px;
    margin-bottom: 40px;
}

.home-top-links-container > h3 {
    font-size: 21px;
    color: #FFFFFF;
    font-family: "Open Sans";
    margin-top: 15px;
}

.home-top-links-container > h3 > a {
    /* color: #fff; */
    /* text-decoration: underline; */
    /* color: #ffff00; */
    color: #868680;
}

.home-top-links-container > h3 > a:hover {
    color: #23527c;
}

.home-top-form-half {
    width: 50%;
    margin: 0 0 0 25px;
    padding-bottom: 40px;
}

.home-top-form-half form {
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.5);
    padding: 24px;
    min-width: 400px;
}

.home-top-form-half form > h1,  .home-top-form-half form > h3 {
    font-family: "Open Sans";
}

.home-top-form-half form > h3 {
    font-size: 14px;
    letter-spacing: 0.29px;
    line-height: 22px;
    text-align: center;
}

.home-top-form-half form > h3 > a {
    color: #FF8A00;
}

.home-top-form-half form > h1 {
    color: #353433;
    margin: 0;
    padding-top: 0;
    padding-bottom: 16px;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: -0.11px;
    line-height: 30px;
}


.home-top-form-half .form-fa-input {
    width: 100%;
    padding-bottom: 0;
}

.home-top-form-half .form-fa-two-input-block {
    width: 100%;
}

.home-top-form-half .form-fa-two-input-block > .form-fa-input > .form-control {
    width: 100%;
}

.home-middle-main-img {
    height: 565px;
    width: 798px;
    margin: 25px auto 100px;
}

.home-middle-main-img > img:nth-child(2), .home-middle-main-img > img:nth-child(3) {
    display: none;
}

.home-middle-main-img > img {
    width: 100%;
    height: auto;
}

.home-middle-under-main-img-block {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.home-middle-under-main-img-block div {
    text-align: center;
}

.home-middle-img-container {
    height: 76px;
}

.home-middle-under-main-img-block > div {
    width: 200px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.home-middle-under-main-img-block div > h2 {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 21px;
    letter-spacing: -0.11px;
    line-height: 28px;
    text-align: center;
    margin: 0 0 10px;
}

.home-middle-under-main-img-block div > h3 {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: 0.29px;
    line-height: 17px;
    text-align: center;
    margin: 0;
}

.home-bottom-container {
    padding: 0 5%;
    margin-top: 120px;
    height: 560px;
    background-color: #F6F6F6;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.06);
}

.home-bottom-container > h1 {
    color: #353433;
    font-family: "Open Sans";
    font-size: 27px;
    font-weight: bold;
    letter-spacing: -0.14px;
    line-height: 37px;
    text-align: center;
    margin: 0;
    padding: 73px 0 85px;
}

.home-bottom-for-block {
    display: flex;
    text-align: center;
    justify-content: space-around;
}

.home-bottom-for-block > div {
    width: 300px;
}

.home-bottom-for-block div > h2 {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 21px;
    letter-spacing: -0.11px;
    line-height: 28px;
    text-align: center;
    margin-top: 5px;
}

.home-bottom-for-block div > h3 {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.29px;
    line-height: 23px;
    text-align: center;
}

.home-bottom-img-container {
    height: 66px;
    position: relative;
}

.home-bottom-img-container > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}


@media screen and (max-width: 1200px) {
    .home-container {
        width: 100%;
    }

    .adsbygoogle {
        height: 100px;
    }

    .home-top-container {
        padding: 128px 5% 0;
    }

    .home-middle-container {
        padding: 0 5% 0;
    }

}

@media screen and (max-width: 960px) {
    .home-middle-main-img {
        height: 487px;
        width: 688px;
    }

    .home-middle-main-img > img:nth-child(1) {
        display: none;
    }

    .home-middle-main-img > img:nth-child(2) {
        display: block;
    }

    .home-middle-under-main-img-block > div {
        width: 50%;
    }

    /* .home-middle-under-main-img-block > div:nth-child(1), .home-middle-under-main-img-block > div:nth-child(2) {
        margin-bottom: 74px;
    } */

    .home-middle-under-main-img-block div > h3 {
        margin: auto;
        width: 80%;
    }

    .home-bottom-for-block > div {
        width: 200px;
    }

    .home-bottom-for-block div > h2 {
        font-size: 19px;
    }

    .home-top-container {
        flex-direction: column;
        background-image: url(/images/imageFlashPicture-tablet2x.png);
    }

    .home-top-form-half {
        margin: auto;
        padding-top: 72px;
        width: 400px;
    }
}


@media screen and (max-width: 768px) {
    .home-top-container {
        background-image: url(/images/imageFlashPicture-tablet.png);
    }
    .home-middle-main-img {
        height: auto;
    }
    .home-middle-main-img {
        width: 100%;
    }

}

@media screen and (max-width: 650px) {
    .home-top-container {
        background-size: 200%;
        background-position: inherit;
    }
    .home-top-form-half {
        padding-bottom: 0;
    }
}

@media screen and (max-width: 600px) {
    .home-bottom-for-block {
        flex-wrap: wrap;
    }

    .home-bottom-for-block > div {
        width: 90%;
    }

    .home-bottom-container {
        height: 100%;
    }

    .home-bottom-container > h1, .home-bottom-for-block > div {
        padding-bottom: 48px;
    }
}

@media screen and (max-width: 500px) {
    .home-top-form-half {
        width: 100%;
    }

    .home-top-container, .home-middle-container {
        padding: 33px 5% 0;
    }

    .home-top-form-half form {
        min-width: auto;
    }
    .home-bottom-container {
        margin-top: 0;
    }
}

@media screen and (max-width: 350px) {
    .home-middle-under-main-img-block > div {
        width: 90%;
    }
}

.fa-button:active, .fa-button:focus {
    outline: none !important;
}

.fa-button {
    border-radius: 32.5px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.13);
    background-image: none;
    /*color: #fff;*/
    margin: auto;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    text-align: center;
}

.fa-button.second {
    background-color: #FF8A00;
    border: 2px solid #FF8A00;
    color: #FFF !important;
}

.fa-button.second.link-to-signup {
    height: 45px;
    width: 150px;
}

.fa-button.second:active,
.fa-button.second:hover,
.fa-button.second:focus{
    background-color: #FF8A00;
    border: 2px solid #FF8A00;
    color: #FFF !important;
}

.welcome-block {
    width: 40%;
    /* margin-left: auto; */
    transition: all 1s;
    margin-bottom: 20px;
}

.closed-welcome-container > .welcome-container {
    height: 0 !important;
    padding: 0 10% !important;
    display: none !important;
}


.closed-welcome-container > .popup-close-button {
    display: none;
}

.welcome-block-ads {
    width: 50%;
    min-width: 50%;
    /* min-height:350px; */
    /* max-width:200px; */
    max-height: 300px;
}

.welcome-block-ads-full, .welcome-block-ads-full-bottom {
    width: 100%;
    min-width: 100%;
    /* min-height:350px; */
    max-height: 300px;
}

.welcome-container {
    /* height: 400px; */
    background-color: #F6F6F6;
    padding: 20px 20px 20px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    top: 0px;
}

@media screen and (max-width: 1152px) {
    .welcome-block-ads, .welcome-block-ads-full {
        /* min-height:150px !important; */
        height: 150px !important;
        max-height: 100px !important;
    }
    .welcome-block {
        width: 80%;
        margin: auto;
    }

    .welcome-block > .link-to-signup {
        width: 100px !important;
        height: 30px !important;
        padding: 0 !important;
        padding: initial !important;
        margin-bottom: 5px !important;
    }
    
    .welcome-block > span > h2, .welcome-block > h2 {
        font-size: 20px !important;
        margin-bottom: 5px !important;
        line-height: 1.1 !important;
    }
    .welcome-block > h4 {
        margin-top: 0px !important;
        font-size: 15px !important;
        line-height: 1.1 !important;
    }
    .welcome-container {
        padding: 10px;
    }
    .welcome-container > .popup-close-button {
        font-size: 32px !important;
    }
    .welcome-block-ads {
        width: 100%;
        min-width: 100%;
    }
}

/* @media screen and (max-width: 900px) {
    .welcome-block {
        width: 100%;
    }
    .welcome-block-ads {
        width: 100%;
        min-width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .welcome-block {
        width: 100%;
    }
    .welcome-block-ads {
        width: 100%;
        min-width: 100%;
    }
} */

@media screen and (max-width: 652px) {
    /* .welcome-container {
        padding: 40px 5%;
    } */

    .welcome-block {
        width: 100%;
    }

    .with-welcome-container > .popup-close-button {
        margin: 5px !important;
    }
    .welcome-block-ads, .welcome-block-ads-full {
        /* min-width: 200px !important; */
        /* width: 200px !important; */
        max-width: 260px !important;
        margin: auto;
        min-width: 260px !important;
        width: 260px !important;
        height: 200px !important;
        min-height: 200px !important;
        max-height: 200px !important;
    }
}

/* @media screen and (max-width: 454px) {
    .welcome-container {
        height: 300px !important;
    }
} */

.welcome-block > span > h2, .welcome-block > h2 {
    color: #353433;
    font-family: "Open Sans";
    font-size: 24px;
    letter-spacing: -0.12px;
    line-height: 33px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 0;
    cursor: pointer;
}

.welcome-block > h4 {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 18px;
    letter-spacing: -0.03px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 10px;
}

.welcome-block > ul > li > h4 {
    font-family: "Open Sans";
    font-size: 15px;
}


.with-welcome-container > .welcome-container > .popup-close-button {
    margin-bottom: 10px;
}

#iframeBlocker {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: aliceblue;
    opacity: 0.1;
}

.link-to-source {
    position: fixed;
    width: 110px;
    z-index: 1050;
    right: 20px;
    margin-top: 20px;
}

.navbar-collapse {
    z-index: 10000 !important;
}

.fa-inline-button-block {
    /* display: inline-block; */
    margin-bottom: 10px;
    text-align: center;
}

.fa-inline-button-block > h4 {
    display: inline;
    font-size: 16px;
    margin-right: 5px;
}

.fa-inline-button-block > button {
    display: inline;
    height: 20px !important;
    width: 70px !important;
    font-size: 14px;
    line-height: 0.5;
    padding: 0px;
}

.feature-ul {
    padding-left: 10%;
}

@media screen and (max-width: 652px) {
    .feature-ul {
        width: 80%;
    }
}
.tooltip-body {
    padding: 24px;
}

.tooltip-title {
    color: rgba(27,83,155,0.8);
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.09px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 16px;
}

.tooltip-message {
    color: #353433;
    font-family: "Open Sans";
    font-size: 18px;
    letter-spacing: 0.09px;
    line-height: 26px;
    text-align: center;
    width: 284px;
}

.rc-tooltip-content > .rc-tooltip-inner {
    border: 1px solid #C3C8C9;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.36), 0 0 4px 0 rgba(0,0,0,0.26);
    padding: 0;
    margin-top: 5px;
}

.rc-tooltip-placement-bottom > .rc-tooltip-content > .rc-tooltip-arrow {
    left: 50%;
    margin-top: 5px;
}

body .rc-tooltip {
    opacity: 1;
}
.form-fa-input {
    width: 408px;
    margin: auto;
    padding-bottom: 20px;
}

.form-fa-input > .form-control {
    min-height: 45px;
}

.form-fa-two-input-block {
    display: flex;
    width: 408px;
    margin: auto;
}

.form-fa-two-input-block > .form-fa-input {
    width: 50%;
}

.form-fa-two-input-block > .form-fa-input > .form-control {
    width: 194px;
}
.form-fa-two-input-block > .form-fa-input:nth-child(2) {
    margin-left: 10px
}

.form-fa-input > label {
    height: 19px;
    width: 100%;
    text-align: left;
    color: #747474;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 19px;
}

.form-fa-input > textarea {
    max-width: 408px;
    max-height: 91px;
    min-height: 91px;
}

.form-fa-submit {
    height: 45px;
    width: 150px;
    border-radius: 32.5px;
    background-color: #3EA900;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    background-image: none;
    color: #fff;
    margin: auto;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    text-align: center;
}

.form-fa-submit:active,
.form-fa-submit:hover,
.form-fa-submit:focus{
    background-color: #55e004;
    border: 2px solid #55e004;
    color: #FFF !important;
    outline: none !important;
}

.sign-form-fa-submit {
    height: 65px;
    width: 241px;
}

.sign-form-fa-submit:hover {
    background-color: #55e004;
    color: #fff;
}

@media screen and (max-width: 500px) {
    .form-fa-two-input-block {
        flex-direction: column;
        width: 100%;
    }

    form > .form-fa-input, .form-fa-two-input-block > .form-fa-input {
        width: 100%;
        margin-left: 0 !important;
    }

    form .form-fa-input > .form-control, form .form-fa-two-input-block > .form-fa-input > .form-control {
        width: 100%;
    }
}
.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
}

.LoaderButton:active,
.LoaderButton:hover,
.LoaderButton:focus{
    background-color: #55e004 !important;
    border: 2px solid #55e004 !important;
    color: #FFF !important;
    outline: none !important;
}

.LoaderButton:active, .LoaderButton:focus {
    outline: none !important;
}

@-webkit-keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

@keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
.landerFA form {
        margin: 0 auto;
    }

.landerFA {
    padding-top:2%;
    padding-left:5%;
    padding-right:5%;
    text-align: center;
}

.landerFA form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
}

.panel, .panel-body {
    max-width: 800px;
    text-align: center;
    margin: auto;
}

#centerDiv {
    margin: 0 auto;
    max-width:400px;
    width:100%;
}
/*.landerFA form {*/
    /*margin: 0 auto;*/
    /*max-width: 800px;*/
    /*width:100%;*/
/*}*/


.landerFA {
    padding-top:2%;
    padding-left:5%;
    padding-right:5%;
    text-align: center;
}


#centralAlert {
    margin: 0 auto;
    max-width: 320px;
}

.loginDiv {
    margin: 0 auto;
    max-width: 400px;
    width:100%;
}

.underform-link {
    margin: 30px 0px;
    height: 100%;
    display: block;
}

.underform-link:hover {
    text-decoration: none;
}

.header-form-alerts {
    color: #999;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

.signin-reset-password-text-block {
    margin: 32px 0 20px;
}

.signin-reset-password-text-block > p, .signin-reset-password-text-block > a {
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.29px;
    line-height: 22px;
    text-align: center;
    margin: 0;
}

.signin-reset-password-text-block > a {
    color: #FF8A00;
}
.fa-button-with-top-margin {
    margin-top: 30px;
}

.ResetPassword1 {
    max-width: 500px !important;
    width: 100% !important;
}

.CenterForm  {
    padding-top:2%;
    padding-left:5%;
    padding-right:5%;
    text-align: left;
    max-width:500px;
    margin: 0 auto;
}

.Subform {
    margin-bottom:55px;

}



.streaming-container {
    padding: 25px;
    margin-bottom: 50px;
    background-color: #F6F6F6;
}

@media screen and (max-width: 820px) {
    .streaming-body {
        flex-direction: column;
    }

    .streaming-sidebar {
        width: 100% !important;
        display: flex;
        flex-wrap: wrap;
        /*justify-content: center;*/
    }

    .streaming-sidebar > .alerts-block-header-checkbox {
        width: 166px;
    }

    .streaming-content {
        margin: auto !important;
    }

}

@media screen and (max-width: 400px) {
    .streaming-container {
        padding: 5px;
    }

}

@media screen and (max-width: 620px) {
    .streaming-body {
        flex-direction: column;
    }

    .streaming-sidebar {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .streaming-sidebar > .alerts-block-header-checkbox {
        padding: 0;
        margin: 0 !important;
        width: 50%;
        justify-content: center;
    }

    .streaming-content {
        margin: auto !important;
    }

    .streaming-container {
        padding: 15px 5px;
    }

}

@media screen and (max-width: 620px) {
    .symbols-title-container {
        flex-direction: column;
    }

    .red-text, .green-text {
        text-align: center;
    }


    .auto-scroll-toggle-block {
        flex-direction: column-reverse;
    }

    .auto-scroll-toggle-block > .sms-info {
        margin: 0 !important;
    }

    .auto-scroll-toggle-block > .react-toggle {

    }
}

.streaming-body {
    display: flex;
}

.streaming-content {
    margin: 20px;
    height: 310px;
    overflow: auto;
    border: 2px solid #AAAA;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
}

.streaming-sidebar {
    width: 20%;
}

.streaming-sidebar > .alerts-block-header-checkbox > h3 {
    margin: 9px 0;
}

.streaming-sidebar > .alerts-block-header-checkbox {
    margin: 10px 0;
}

.streaming-message {
    background-color: #fff;
    border: 2px solid #AAA;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 10px;
    overflow: hidden;
}

.streaming-top-head-container {
    display: flex;
    justify-content: space-between;
}

.auto-scroll-toggle-block {
    display: flex;
    width: 20%;
}

.auto-scroll-toggle-block > .sms-info {
    margin: auto 15px;
}

.auto-scroll-toggle-block > .sms-info > h3 {
    color: #AAAAAA;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 19px;
}

.green-text {
    color: #19ab27 !important;
}

.red-text {
    color: tomato !important;
}

.streaming-top-head-container > #tickerSelect {
    min-width: auto;
    width: 80%;
}

@media screen and (max-width: 1234px) {
    .auto-scroll-toggle-block {
        width: 25%;
    }

    .streaming-top-head-container > #tickerSelect {
        width: 70%;
    }

    .streaming-sidebar > .alerts-block-header-checkbox > h3 {
        font-size: 15px;
        min-width: 111px;
    }
}
.landerFA {
    padding-top:2%;
    padding-left:5%;
    padding-right:5%;
    text-align: center;
}

#ListGroupItemHeader {
    font-weight: bold;
}

#ListGroupItemFeatures {
    border: 0px;
}

#ListGroupItemFeaturesBoldItem {
    font-weight: bold;
    border: 0px;
}

.centered-title {
    text-align: center !important;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    font-family: "Open Sans";
}

.flex-container .list-group .media-left {
    float: left;
    margin: 5px;
}

.flex-container .list-group .list-group-item {
    position: static;
    position: initial;
}


.flex-container > .media {
    flex: 50% 1;
    padding: 15px 0;
    margin: 0;
    font-family: "Open Sans";
}

.flex-container .media-heading {
    font-weight: 700;
    font-family: "Open Sans";
}

.flex-container h4.media-heading {
    margin-bottom: 15px;
}

.page-container > .page-media {
    padding: 10px 10% 80px;
}

.prodcuts-list > li {
    padding-bottom: 5px;
}

.flex-container > .media-body {
    width: auto;
}

.media-body-top {
    height: 400px;
}

.products-flex-media > .media-body-top > p {
    color: #727272;
}

.products-list > li > p {
    color: #727272;

}

.products-list-header {
    padding-bottom: 5px;
    
}

.products-list {
    padding-left: 15px;
}

.products-media > .media > .media-body {
    padding-bottom: 20px;
}

.products-media > .flex-container > .media {
    max-width: 32.5rem;
    display: flex;
    flex-direction: column;
    /* border: 2px solid #1B539B; */
    border: 2px solid #FF8A00;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    background-color: white;
}

.products-media > .flex-container {
    justify-content: space-between;
}

.page-media p {
    color: #999;
    margin: 0px;
}

.page-media p > .media-left {
    float: left;
}

.page-media p > .media-left > h2 {
    margin: 0;
    padding: 0;
}

.media > .media-body {
    padding-bottom: 50px;
    width: auto;
}

.media-body-bottom {
    /* margin-top: auto; */
    padding-bottom: 0px !important;
}

.page-media > .flex-container > .media > .media-body > p > .media-left > h2 {
    margin: 10px;
    padding: 0;
}

.page-media > .media > .media-body > .media-heading {
    font-weight: 700;
    font-family: "Open Sans";
    margin-bottom: 15px;
}

@media screen and (max-width: 780px) {
    .flex-container {
        flex-direction: column;
    }

    .page-media p > .media-left > h2 {
        margin: 10px;
    }
}
.page-container {
    width: 88%;
    margin: auto;
    /* text-align: justify; */
}

@media screen and (max-width: 1200px) {
    .page-container {
        width: 100%;
    }
}

.page-grey-block {
    padding: 60px 10%;
    background-color: #F6F6F6;
    margin-bottom: 30px;
}

.page-title {
    color: #353433;
    font-family: "Open Sans";
    font-size: 27px;
    font-weight: bold;
    letter-spacing: -0.14px;
    line-height: 37px;
    text-align: left;
    margin: 0 39px 0px 0;
}

.page-title-by-center {
    text-align: center;
    margin: 0 0 20px 0;
}

.page-title-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.profileTable {
    margin: 0 auto;
    border: none;
}

.profileTable > tbody > tr > td {
    text-align: center;
}

.profile-button-block {
    border: 2px solid #337ab7;
    border-radius: 8px;
}

.profileTable.table-bordered > tbody > tr > td {
    /*border: 3px solid #ddd !important;*/
    border: none;
}

@media screen and (max-width: 501px) {
    .profile-button-block {
        display: flex;
        flex-direction: column;
    }
}

.page-form {
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.5);
    padding: 24px;
    width: 50%;
}

.page-form-fullwidth {
    width: 100% !important;
}

.profile-form .form-fa-input > label, .profile-form .form-fa-input > span {
    display: none;
}

.profile-form .form-fa-input {
    width: 100%;
    padding: 5px;
}

.profile-form .cancel-button {
    margin: 5px;
}

.profile-form .fa-submit-button {
    margin: 5px;
}

.profile-form > .table-responsive > .table > tbody > tr > td {
    vertical-align: middle;
}

.page-form > .form-fa-input {
    min-width: auto;
    width: auto;
    padding-bottom: 10px;
}

@media screen and (max-width: 1000px) {
    .page-form {
        width: 70%;
    }
}

@media screen and (max-width: 630px) {
    .page-form {
        width: 100%;
    }
}

.cancel-button {
    color: #FFF !important;
    outline: none !important;
    height: 45px;
    width: 150px;
    border-radius: 32.5px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    background-image: none;
    margin: auto;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    text-align: center;
    transition: 0.4s all;
}

.fa-submit-button {
    color: #FFF !important;
    outline: none !important;
    height: 45px;
    width: 150px;
    border-radius: 32.5px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    background-image: none;
    margin: auto;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    text-align: center;
    transition: 0.4s all;
}

.page-form-button-container {
    margin: 30px 0;
}

.page-form > .page-form-button-container > a, .page-form-button-container > button {
    margin: 10px;
    min-width: 150px;
}

@media screen and (max-width: 500px) {
    .page-form > .page-form-button-container > a {
        margin: 10px auto !important;
    }

    .page-form > .page-form-button-container {
        flex-direction: column;
    }
}

.cancel-button,
.cancel-button:active,
.cancel-button:hover,
.cancel-button:focus {
    background-color: #FF8A00 !important;
    border: 2px solid #FF8A00 !important;
    color: #FFF !important;
    outline: none !important;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13) !important;
}

.fa-submit-button,
.fa-submit-button:active,
.fa-submit-button:hover,
.fa-submit-button:focus {
    background-color: #3EA900 !important;
    border: 2px solid #3EA900 !important;
    color: #FFF !important;
    outline: none !important;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13) !important;
}

.alerts-container {
    width: 88%;
    margin: auto;
}

form {
    margin: 0 auto;
    width: 100%;
}

.top-checkboxes-container {
    display: flex;
}

.grey-block {
    padding: 80px 10%;
    background-color: #F6F6F6;
}

.alerts-grey-block-top-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.button-container-reverse > .button-reverse {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0px;
}

/* .alerts-top-left-block {
    display: flex;
    justify-content: space-between;
} */

.alerts-top-left-block > h1 {
    color: #353433;
    font-family: "Open Sans";
    font-size: 27px;
    font-weight: bold;
    letter-spacing: -0.14px;
    line-height: 37px;
    /* text-align: center; */
    margin: 0 39px 0px 0;
}

.alerts-top-left-block > .form-group > h3 {
    color: #353433;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.13px;
    line-height: 24px;
    margin: auto 23px auto 0;
}

.sms-info > h3 {
    color: #353433;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.13px;
    line-height: 15px;
    margin: 0;
}

.alerts-top-left-block > .form-group, .alerts-top-right-block > .form-group {
    display: flex;
    margin: auto;
}

.alerts-top-right-block {
    margin: auto 0;
    display: flex;
    flex-direction: column;
}

.alerts-top-right-block > .form-group {
    margin: 0;
}

.sms-info {
    margin-right: 26px;
    margin-bottom: -3px;
}

.sms-info > span {
    color: #AAAAAA;
    font-family: "Open Sans";
    font-size: 10px;
    letter-spacing: -0.14px;
    line-height: 14px;
}

.sms-info > span > i {
    background-image: url(/images/iconInfoSign.png);
    width: 14px;
    height: 14px;
    display: inline-block;
    margin-bottom: -3px;
    margin-right: 5px;
}

.alerts-grey-block-bottom-container > h4 {
    color: #AAAAAA;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 19px;
}

.symbols-title-container > h4 {
    color: #AAAAAA;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 19px;
}

.alerts-middle-container {
    padding: 60px 10%;
}


.alerts-middle-container > .container-title, .alerts-middle-container > .alerts-title-block > .container-title {
    color: #353433;
    font-family: "Open Sans";
    font-size: 27px;
    letter-spacing: -0.14px;
    line-height: 37px;
    margin: 0;
    padding-bottom: 36px;
}

.container-title > .item-warn {
    display: contents;
}

.alerts-block-header {

}

.alerts-block-header-checkbox {
    display: flex;
    margin: 15px;
}

.alerts-block-header-checkbox > h3 {
    display: inline-block;
    color: #353433;
    font-family: "Open Sans";
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.12px;
    line-height: 23px;
    margin-top: 10px;
}

.alerts-block-header > hr {
    margin: 0 -1px;
    border-radius: 3px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    border-top: 1px solid #D8DDDF;
}

.grey-block > .container-title {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 27px;
    letter-spacing: -0.14px;
    line-height: 37px;
    margin: 0;
    padding-bottom: 28px;
}

.items-container {
    border: 1px solid #D8DDDF;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    padding: 28px 20px;
    margin-bottom: 70px;
}

.item-warn {
    color: #AAAAAA;
    font-family: "Open Sans";
    font-size: 10px;
    letter-spacing: -0.14px;
    line-height: 14px;
    display: block;
}

.item-warn > i {
    height: 13px;
    width: 13px;
    background-image: url(/images/iconAlertSign.png);
    display: inline-block;
    margin-bottom: -2px;
    margin-right: 5px;
}

form span.help-block {
    color: #999;
}

#tickerSelect {
    width: 100%;
    min-width: 100%;
}

.AlertsLabel {
    font-weight: 100 !important;
    white-space: normal;
}

.subForm{
    margin: 0 auto;
    width: 100%;
    display: flex;
}

#secCheckboxes {
    text-align: left;
}

.landerFA h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.landerFA p {
    color: #999;
}

.landerFA table, tr, td {
    text-align: left;
}

.panelDiv {
    margin: 0 auto;
    width: 100%;
}

.checkbox-block {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.checkbox-block .form-group {
    /*height: 258px;*/
    width: 312px;
    border: 1px solid #D8DDDF;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    margin: 0 22px 22px 0;
    min-width: 292px;
}

.alerts-block-body {
    margin: 15px;
}

.alerts-block-body > .checkbox-container > .checkbox-label {
    font-weight: 400;
}

.alerts-block-without-body > .form-group {
    height: 70px;
}

.checkbox-block h3 {
    margin: auto 0;
}

.alerts-block-header-checkbox > .UD-title {
    word-spacing: -5px;
    line-height: 18px;
}

.checkbox-block .checkbox {
    margin-left: 15px;
}

.checkbox-block .select-all {
    margin-left: 0;
}

.checkbox-container {
    display: flex;
}

.checkbox-label {
    margin: auto 0;
    font-weight: 400;
}

.alerts-button-container {
    display: flex;
    justify-content: center;
    margin: 30px auto;
    max-width: 1000px;
}

.alerts-submit-button {
    height: 65px;
    width: auto;
    min-width: 241px;
    border-radius: 32.5px;
    background-color: #3EA900;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    margin: 0 0 0 20px;
}

@media screen and (max-width: 1200px) {
    .alerts-container {
        width: 100%;
    }

    .grey-block, .alerts-middle-container {
        padding: 40px;
    }

    /*.home-top-container, .home-middle-container {*/
        /*padding: 128px 5% 0;*/
    /*}*/

}

@media screen and (max-width: 1030px) {
    .alerts-top-left-block {
        flex-direction: column;
    }

    .alerts-top-left-block > h1 {
        text-align: left;
        margin-bottom: 45px;
    }

    .alerts-top-right-block {
        margin: auto 0 12px 0;
    }
}

@media screen and (max-width: 950px) {
    .checkbox-block .form-group {
        width: 250px;
    }

    .checkbox-block h3, .alerts-block-body > .checkbox-container > .checkbox-label {
        font-size: 14px;
    }
}

.preloadContent {
    height: 100px;
}

.align-left {
    text-align: left;
}

@media screen and (max-width: 678px) {
    .alerts-top-left-block > .form-group {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .top-checkboxes-container {
        margin-left: -10px;
    }

    .checkbox-block {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 624px) {
    .checkbox-block .form-group {
        width: 100%;
        margin: 0 0 22px 0;
    }

    .alerts-grey-block-top-container {
        flex-direction: column;
    }

    .alerts-top-left-block {
        margin-bottom: 30px;
    }

    .alerts-top-left-block > h1 {
        margin-bottom: 30px;
    }

}

@media screen and (max-width: 500px) {
    .checkbox-block-container {
        margin-bottom: 10px;
    }

    .alerts-button-container {
        flex-direction: column-reverse;
    }

    .grey-block > .button-container-reverse {
        flex-direction: column !important;
    }

    .alerts-button-container > button {
        /*padding: 0;*/
        margin: 10px auto !important;
    }

    .items-container {
        margin-bottom: 0;
    }

    .AlertsLabel {
        font-size: 1.4rem;
    }

    form label {
        font-size: 1.4rem;
    }
}

.red {
    color: tomato;
}

.bold-red {
    font-weight: bold;
    color: tomato !important;
    display: inline;
}

.symbols-checkboxes-container {
    margin-left: 15px;
    margin-top: 3px;
}
.symbols-checkboxes-container > div > label > span:nth-child(2) {
    font-size: 16px;
    margin: auto 0;
    font-weight: 400;
    margin-left: -6px;
}

.symbols-checkboxes-container > div {
    display: flex;
    flex-direction: initial;
    justify-content: space-evenly;
}

.symbols-title-container {
    display: flex;
    margin-bottom: 10px;
}

@media screen and (max-width: 410px) {
    .symbols-title-container {
        flex-direction: column;
    }
}

.fa-radio-button {
    color: #018786 !important;
}

.symbols-checkboxes-container > div > label > span:nth-child(2) > p {
    font-size: 16px;
    margin: auto 0;
    font-weight: 400;
    margin-left: -6px;
}

.symbols-checkboxes-container > .MuiButtonGroup-vertical {
    flex-direction: column;
}

.symbols-checkboxes-container > .MuiButtonGroup-vertical {
    flex-direction: column;
}

.checkbox-premium-label {
    line-height: 1.5 !important;
}

.via-checkbox-block {
    display: flex;
    margin-left: auto;
}

.checkbox-block-container {
    display: flex;
}

.items-container > .checkbox-block-container > div {
    line-height: 23px;
}

.items-container > .alerts-block-body > .checkbox-block-container > div {
    line-height: 23px;
}

.checkbox-block-container > div {
    line-height: 35px;
}

.disabled {
    opacity: 0.5;
}

.via-checkbox-block > .checkbox-container > .checkbox-label {
    font-size: 12px;
    margin-left: 3px;
    margin-right: 3px;
}

.via-checkbox-block > .checkbox-container > .fa-ui-checkbox {
    padding: 6px;
}
.via-checkbox-block > .checkbox-container > .fa-ui-checkbox > span > svg {
    width: 18px;
}

.checkbox-container > span > span.MuiIconButton-label {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
}

@media screen and (max-width: 960px) {
    .checkbox-block {
        flex-wrap: wrap;
    }
    .form-group {
        margin: 22px auto auto auto;
    }
}

.empty-check-div {
    width: 54px;
    margin-right: 3px;
    margin-left: 3px;
}

.fa-ui-checkbox-color {
    color: #018786 !important;
}

.item-warn-big {
    font-size: 15px;
    line-height: 15px;
}

.alerts-title-block {
    display: flex;
    margin-bottom: 25px;
}

.alerts-title-block > h3 {
    padding-bottom: 0 !important;
    line-height: 43px !important;
}

.fa-ui-checkbox-tooltip {
    font-size: 14px !important;
    margin-bottom: 5px !important;
    /* padding-bottom: 0px !important; */
}

.alerts-top-left-block > .alerts-subs-details {
    font-size: 16px;
    margin: auto 0;
    font-weight: 400;
    text-align: start;
}

.subs-info-block a {
    color: rgb(255, 138, 0);
}

.subs-info-block a:hover {
    color: #F29746;
}

.service-button-group > button {
    background: rgba(1, 135, 135, 0.5);
    color: white !important;
    border-color: white !important;
    text-transform: none;
    font-size: 13px;
}

.service-button-group > button:hover {
    background: rgba(1, 135, 135, 0.8);
}

.active-button {
    background: #018786 !important;
}

.service-button-group > button > span > p {
    margin: 0px;
}

.react-toggle {
    margin: auto;
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 60px;
    height: 29px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 25px;
    height: 25px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;
    box-sizing: border-box;
    transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 33px;
    border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
    box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: 0px 0px 5px 5px #0099E0;
}


.alerts-button {
    height: 35px;
    width: auto;
    border: 2px solid tomato;
    border-radius: 32.5px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    color: #fff;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    background-image: none;
    margin: 10px 20px 10px 10px;

}

.alerts-button .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
}

.alerts-button-checked,
.alerts-button-checked:hover,
.alerts-button-checked:active,
.alerts-button-checked:focus{
    background-color: transparent !important;
    background-color: initial !important;
    border: 2px solid tomato !important;
    color: tomato !important;
    outline: none !important;
}

.alerts-button-unchecked,
.alerts-button-unchecked:hover,
.alerts-button-unchecked:active,
.alerts-button-unchecked:focus {
    background-color: tomato !important;
    border: 2px solid tomato !important;
    color: #fff !important;
    outline: none !important;
}

.unsubscribe-button {
    /* width: 130px !important; */
    min-width: 130px !important;
    margin-left: auto;
    margin-right: 0px;
    margin-top: 20px;
}
.unselect-alerts-button {
    height: 65px;
    width: 241px;
    border: 2px solid #FF8A00;
    border-radius: 32.5px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    color: #fff;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    background-image: none;
    margin: 0 20px 0 0;

}

.unselect-alerts-button-checked,
.unselect-alerts-button-checked:hover,
.unselect-alerts-button-checked:active,
.unselect-alerts-button-checked:focus{
    background-color: transparent !important;
    background-color: initial !important;
    border: 2px solid #FF8A00 !important;
    color: #FF8A00 !important;
    outline: none !important;
}

.unselect-alerts-button-unchecked,
.unselect-alerts-button-unchecked:hover,
.unselect-alerts-button-unchecked:active,
.unselect-alerts-button-unchecked:focus {
    background-color: #FF8A00 !important;
    border: 2px solid #FF8A00 !important;
    color: #fff !important;
    outline: none !important;
}
.landerFA {
    margin: 0 auto !important;
    padding-top:2%;
    padding-left:5%;
    padding-right:5%;
    text-align: center;
}

.landerText {
    margin: 0 auto !important;
    text-align: left !important;
    max-width: 800px;
    padding:5%;
}

.title-with-bottom-padding {
    padding-bottom: 20px;
}

.title-with-padding {
    padding: 20px 0;
}
.landerFA {
    padding-top:2%;
    padding-left:5%;
    padding-right:5%;
    text-align: center;
}
.Home {
    margin: 0 auto;
}

.landerFA {
    padding-top:2%;
    padding-left:5%;
    padding-right:5%;
    text-align: center;
}

.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .lander p {
    color: #999;
}

#homePanel {
    border: 0;
}

#imgRounded {
    border-radius: 15%;
}

.label-wrapper > .label {
    display: inline-block;
    padding: .5em .6em .5em;
    width: 160px;
}
.Footer {
    padding-top: 100px;
    text-align: center;
    margin: 0 auto;
    color: #54504B;
    font-family: "Open Sans";
    font-size: 18px;
}

.lander {
    margin-bottom: 25px;
}

.footer-bottom {
    border-top: 1px solid #DDE1E4;
    margin-top: 30px;
    padding-top: 30px;
}

.footer-bottom > h1 {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 27px;
    letter-spacing: -0.14px;
    line-height: 37px;
    text-align: center;
    width: 90%;
    margin: 0 auto 40px;
}

.footer-bottom-links {
    box-shadow: none;
    background: none;
    border: none;
}

.footer-bottom-links > .container > h4 {
    width: 50%;
    color: #AAAAAA;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.01px;
    line-height: 19px;
    float: right;
    text-align: right;
}

.footer-bottom-links > .container {
    width: auto;
    padding: 0 5%;
}

@media screen and (max-width: 800px) {
    .footer-bottom-links > .container > h4 {
        width: 100%;
    }

    .Footer {
        padding-top: 48px;
    }

    .footer-bottom-links > .container > .navbar-nav {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin: 0;
    }

    .footer-bottom-links > .container > h4 {
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    .footer-bottom-links > .container > .navbar-nav > li > a {
        padding: 10px 0;
    }
}
.contact-us-container {
    width: 88%;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: row-reverse;
}

@media screen and (max-width: 930px) {
    .contact-us-container {
        flex-direction: column-reverse;
    }

    .footer-text {
        width: 100% !important;
        margin: auto;
        padding-bottom: 40px;
    }
}

@media screen and (max-width: 768px) {
    .contact-us-container > .footer-text {
        width: 95%;
    }

    .contact-us-container > #contact-form {
        width: 95%;
    }
}

.footer-text {
    width: 50%;
}

.footer-text > h3, .footer-text > h3 > a {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 18px;
    letter-spacing: -0.03px;
    line-height: 28px;
    text-align: left;
}

.footer-text > h3 > a {
    cursor: pointer;
    color: #FF8A00;
    display: inline;
}

#headerContact {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    max-width: 600px;
    padding-bottom:1%;
}

#headerContact > div > div > div {
    margin: auto;
}

/*#email {*/
    /*width:100%;*/
    /*max-width:400px;*/
/*}*/

#contact-form {
    width: 50%;
    max-width: 600px;
    margin: 0 auto;
}


.contact-form-without-text {
    width: 100%;
}


.header {
    background: none;
    border: none;
    height: 55px;
    box-shadow: none;
    display: flex;
    align-items: flex-end;
    margin: 0;
}

.right-nav-container {

}

.sign-nav-container {
    width: 174px;
    display: flex;
    margin-left: 5px;
    display: flex;
    justify-content: space-around;
}

.social-nav-container {
    width: 160px;
    display: flex;
    justify-content: space-around;
}

@media (min-width: 1200px) {
    .container {
        width: 91.5%;
    }
}

@media screen and (max-width: 1200px){
    .header > .container {
        width: 90%;
        padding: 0;
    }
}

 @media screen and (min-width: 768px) and (max-width: 992px) {
    .header {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 992px) {

    .right-nav-container {
        display: flex;
        flex-direction: column-reverse;
    }

    .social-nav-container {
        margin-left: 15px;
    }
    .header > .container > .navbar-collapse {
        position: absolute;
        right: 5%;
    }
}

@media screen and (max-width: 768px) {
    .navbar-toggle.collapsed:after, .navbar-toggle.collapsed:before{
        opacity: 0;
        transition: 0.4s all;
    }

    .navbar-toggle:after, .navbar-toggle:before {
        transition: 0.4s all;
        opacity: 1;
    }

    .navbar-toggle:after, .navbar-toggle:before {
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        z-index: 1100;
        top: 49%;
        right: 7%;
        opacity: 1;
    }

    .navbar-toggle:after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: #fff;
        border-width: 22px;
        top: 55%;
    }

    .navbar-toggle:before {
        border-color: rgba(194, 225, 245, 0);
        border-bottom-color: #C3C8C9;
        border-width: 22px;
    }
    .header > .container > .navbar-collapse {
        top: 128%;
        z-index: 1000;
        background-color: #fff;
        border: 1px solid #C3C8C9;
        border-radius: 8px;
        box-shadow: 0 4px 16px 0 rgba(0,0,0,0.36), 0 0 4px 0 rgba(0,0,0,0.26);
        width: 250px;

    }

    .row > .navbar-default .navbar-nav > li > a, .row > .navbar-default .navbar-nav > li > a > a {
        font-size: 20px;

    }

    .nav-header > .navbar-toggle {
        margin: 5px;
    }

    .header > .container {
        width: 90%;
    }

    .nav-links {
        padding: 0 15px;
    }

    .nav-links > li {
        border-bottom: 1px solid #DDE1E4;
    }

    .right-nav-container {
        padding: 0 15px;
    }

    .right-nav-container > ul {
        border-bottom: 1px solid #DDE1E4;
        margin: 0;
        width: auto;
    }
    .row > .navbar-default .navbar-nav > li.active-sign {
        margin: 5px;
    }
}

.navbar-default .navbar-nav > li.active-sign {
    color: #fff !important;
    border-radius: 3px;
    background-color: #FF8A00;
    /*height: 25px;*/
    width: 74px;
    margin: 10px;
}

.navbar-default .navbar-nav > li > a, .navbar-default .navbar-nav > li > a > a {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
}

.navbar-default .navbar-nav > li.active-sign > a, .navbar-default .navbar-nav > li.active-sign > a > a {
    color: #FFFFFF;
    padding: 0;
    margin: 0;
    text-align: center;
    line-height: 1.7;
}

.navbar-default .navbar-nav > li.twitter-nav > a {
    padding-top: 10px;
}

.navbar-default .navbar-nav > li > a > a:focus, .navbar-default .navbar-nav > li > a > a:hover {
    /*color: #333;*/
    background-color: transparent;
    text-decoration: none;
}

.navbar-default .navbar-nav > li.stocktwits-nav > a {
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 0;
}

.navbar-default .navbar-nav > li > a > a {
    color: #777;
}

.container > .nav-header > a, .container > .nav-header > a > a {
    line-height: 0.6;
    color: #FF8A00;
    font-family: "Open Sans";
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.55px;
    padding-top: 5px;
}

.container > .nav-header > a:hover, .container > .nav-header > a > a:hover {
    color: #FF8A00;
}

.container > .nav-header > a > img {
    display: inline-block;
    margin-right: 5px;
    margin-top: -5px;
}

.header-text {
    display: inline;
    font-size: 16px;
    margin: 0;
    padding: 0;
    padding-top: 10px;
    text-align: center;
    cursor: pointer;
}

@media screen and (max-width: 550px){
    .nav-fa-block {
        display: inline-flex;
        justify-content: space-between;
    }
}

.header-tooltip-block > p {
    font-size: 14px;
    line-height: 1.5;
}

.shorter-tooltip {
    padding: 5px !important;
}
.popup-container {
    position: fixed;
    top: -40px;
    left: calc(50% - 165px);
    width: 332px;
    border: 1px solid #FFC580;
    border-radius: 8px;
    background-color: #FFE3C2;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.36), 0 0 4px 0 rgba(0,0,0,0.26);
    transition: all 0.5s;
    opacity: 0;
    z-index: -1;
    height: 0% !important;
}

.popup-active {
    opacity: 1;
    top: 40px;
    z-index: 1000;
    height: auto !important;
}

.big-popup-container.popup-active {
    height: 700px !important;
}

.popup-close-button {
    float: right;
    padding: 10px;
    line-height: 1;
    font-size: 20px;
    cursor: pointer;
    line-height: 10px;
    color: #777;
}

.popup-body {
    margin: 36px 25px;
    color: #353433;
    font-family: "Open Sans";
    font-size: 18px;
    letter-spacing: 0.09px;
    line-height: 26px;
    text-align: center;
}

.popup-title {
    color: #577fb5;
    margin-bottom: 20px;
    text-align: center;
}

.popup-message {

}

.popup-active-priority {
    z-index: 1500;
}


.popup-buttons-block {
    margin: 20px 0;
    display: flex;
}

.popup-buttons-block > button,
.popup-buttons-block > button:active,
.popup-buttons-block > button:hover,
.popup-buttons-block > button:focus
{
    color: #FFF !important;
    outline: none !important;
    height: 45px;
    width: 120px;
    border-radius: 32.5px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    background-image: none;
    margin: auto;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    text-align: center;
    /* margin: 5px 25px; */
    transition: 0.4s all;
}

.popup-container.popup-active > .popup-body > .popup-buttons-block > button {
    opacity: 1;
}

.popup-container > .popup-body > .popup-buttons-block > button {
    opacity: 0;
}

.popup-buttons-block > button.answer-yes-button {
    background-color: #3EA900;
}

.popup-buttons-block > button.answer-no-button {
    background-color: #FF8A00 !important;
}


.popup-buttons-block > button.answer-yes-button:active,
.popup-buttons-block > button.answer-yes-button:hover,
.popup-buttons-block > button.answer-yes-button:focus{
    background-color: #55e004;
    border: 2px solid #55e004;
    color: #FFF !important;
    outline: none !important;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13) !important;
}

.popup-buttons-block > button.answer-no-button:active,
.popup-buttons-block > button.answer-no-button:hover,
.popup-buttons-block > button.answer-no-button:focus {
    background-color: #FF8A00 !important;
    border: 2px solid #FF8A00 !important;
    color: #FFF !important;
    outline: none !important;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13) !important;
}

.question-popup-message > p {
    text-align: left;
    font-size: 15px;
}

.question-popup-message > li {
    font-size: 15px;
    text-align: left;
    padding-bottom: 10px;
}
.popup-buttons-block {
    margin: 20px 0;
}

.big-popup-container {
    left: calc(57% - 520px);
    border: 1px solid #000;
    background-color: #FFF;
    width: 900px;
    height: 700px;
    overflow-X: auto;
}


.big-popup-container.popup-active {
    top: 5px;
}

/*.big-popup-container {*/
    /*left: calc(11vw - 1%);*/
    /*height: 700px;*/
/*}*/

.big-popup-container .popup-message {
    text-align: left;
}

.big-popup-container > .popup-body > .popup-message {
    margin-bottom: 5px;
}

.big-popup-container > .popup-body > .popup-buttons-block > button {
    width: 15%;
    min-width: 100px;
}
.big-popup-container > .popup-body > .popup-buttons-block {
    margin: 10px 0;
}

.popup-container.popup-active > .popup-body > .popup-buttons-block > button {
    opacity: 1;
}

.popup-container > .popup-body > .popup-buttons-block > button {
    opacity: 0;
}

.popup-buttons-block > button.answer-yes-button {
    background-color: #3EA900;
}

.popup-buttons-block > button.answer-no-button {
    background-color: #FF8A00 !important;
}


.popup-buttons-block > button.answer-yes-button:active,
.popup-buttons-block > button.answer-yes-button:hover,
.popup-buttons-block > button.answer-yes-button:focus{
    background-color: #55e004;
    border: 2px solid #55e004;
    color: #FFF !important;
    outline: none !important;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13) !important;
}

.popup-buttons-block > button.answer-no-button:active,
.popup-buttons-block > button.answer-no-button:hover,
.popup-buttons-block > button.answer-no-button:focus {
    background-color: #FF8A00 !important;
    border: 2px solid #FF8A00 !important;
    color: #FFF !important;
    outline: none !important;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13) !important;
}

.stripe-card {
    border-radius: 5px;
    background-color: #f6f9fc;
    padding: 20px;
}

.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    min-width: 200px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

.stripe-card label {
    color: #6b7c93;
    width: 100%;
}

.stripe-card button {
    /*display: block;*/
    /*width: 100%;*/
    /*height: 37px;*/
    /*background-color: #6772e5;*/
    /*border-radius: 2px;*/
    /*font-weight: 600;*/
    /*color: #fff;*/
    /*cursor: pointer;*/
    /*width: 30%;*/
    margin: auto;
}

.stripe-card .form-fa-input, .stripe-card .form-fa-two-input-block {
    width: 100%;
    max-width: 800px;
}

.stripe-card .form-fa-two-input-block > .form-fa-input > input {
    width: 100%;
}

.split-form {
    display: flex;
    display: flex;
    max-width: 800px;
    margin: auto;

}

.split-form > label > label {
    height: 19px;
    width: 100%;
    text-align: left;
    /*color: #747474;*/
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 19px;
}

.split-form > label:nth-child(2) {
    margin-left: 10px;
}

.split-form > .form-fa-input {
    margin-left: 10px;
    margin-top: 0px;
}

.split-form > label > div {
    display: block;
    width: 100%;
    height: 44px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    margin-top: 0px;
    padding-top: 12px;
    min-width: 100%;
}

@media screen and (max-width: 950px) {
    .big-popup-container > .popup-body > .popup-message {
        font-size: 13px;
    }
    .big-popup-container > .popup-body > .symbols-checkboxes-container > div > label > span:nth-child(2) {
        font-size: 12px;
    }
    .stripe-card {
        padding: 10px;
    }

    .big-popup-container > .popup-body {
        margin: 13px 12px;
    }

    .big-popup-container {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0 !important;
    }
}

@media screen and (max-width: 800px) and (max-height: 500px) {
    .big-popup-container {
        top: 5px;
    }
}

@media screen and (max-width: 650px) {
    .card-confirm, .card-info {
        font-size: 13px;
    }
}

@media screen and (max-width: 514px) {
    .card-confirm {
        line-height: 1.5 !important;
    }
    .card-confirm > .checkbox-container > span {
        padding-top: 0;
    }
}

.card-confirm > .checkbox-container {
    display: inline-block;
}

.popup-body > .card-info {
    display: block;
    text-align: left;
}

.card-confirm {
    /* line-height: 4;
    display: flex; */
}

@media screen and (max-width: 1100px) {


}

.error {
    font-weight: bold;
    color: red;
    padding-bottom: 10px;
}
.cookie-form-container {
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 80px;
    border: 1px solid #FFC580;
    background-color: #FFE3C2;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.36), 0 0 4px 0 rgba(0,0,0,0.26);
    z-index: 5000;
}

@media screen and (max-width: 761px) {
    .cookie-form-container > .cookie-container > .cookie-block > h4 {
        font-size: 14px !important;
    }
    
}

.cookie-form-container > .cookie-container {
    background-color: #FFE3C2;
    padding: 20px 10%;
}

.cookie-form-container > .cookie-container > .cookie-block {
    width: 100%;
}

.cookie-form-container > .cookie-container > .cookie-block > h4 {
    font-size: 16px;
    text-align: center;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  overflow: visible;
  z-index: 1;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

