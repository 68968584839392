.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
}

.LoaderButton:active,
.LoaderButton:hover,
.LoaderButton:focus{
    background-color: #55e004 !important;
    border: 2px solid #55e004 !important;
    color: #FFF !important;
    outline: none !important;
}

.LoaderButton:active, .LoaderButton:focus {
    outline: none !important;
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
}