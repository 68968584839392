.page-container {
    width: 88%;
    margin: auto;
    /* text-align: justify; */
}

@media screen and (max-width: 1200px) {
    .page-container {
        width: 100%;
    }
}

.page-grey-block {
    padding: 60px 10%;
    background-color: #F6F6F6;
    margin-bottom: 30px;
}

.page-title {
    color: #353433;
    font-family: "Open Sans";
    font-size: 27px;
    font-weight: bold;
    letter-spacing: -0.14px;
    line-height: 37px;
    text-align: left;
    margin: 0 39px 0px 0;
}

.page-title-by-center {
    text-align: center;
    margin: 0 0 20px 0;
}

.page-title-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.profileTable {
    margin: 0 auto;
    border: none;
}

.profileTable > tbody > tr > td {
    text-align: center;
}

.profile-button-block {
    border: 2px solid #337ab7;
    border-radius: 8px;
}

.profileTable.table-bordered > tbody > tr > td {
    /*border: 3px solid #ddd !important;*/
    border: none;
}

@media screen and (max-width: 501px) {
    .profile-button-block {
        display: flex;
        flex-direction: column;
    }
}

.page-form {
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.5);
    padding: 24px;
    width: 50%;
}

.page-form-fullwidth {
    width: 100% !important;
}

.profile-form .form-fa-input > label, .profile-form .form-fa-input > span {
    display: none;
}

.profile-form .form-fa-input {
    width: 100%;
    padding: 5px;
}

.profile-form .cancel-button {
    margin: 5px;
}

.profile-form .fa-submit-button {
    margin: 5px;
}

.profile-form > .table-responsive > .table > tbody > tr > td {
    vertical-align: middle;
}

.page-form > .form-fa-input {
    min-width: auto;
    width: auto;
    padding-bottom: 10px;
}

@media screen and (max-width: 1000px) {
    .page-form {
        width: 70%;
    }
}

@media screen and (max-width: 630px) {
    .page-form {
        width: 100%;
    }
}

.cancel-button {
    color: #FFF !important;
    outline: none !important;
    height: 45px;
    width: 150px;
    border-radius: 32.5px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    background-image: none;
    margin: auto;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    text-align: center;
    transition: 0.4s all;
}

.fa-submit-button {
    color: #FFF !important;
    outline: none !important;
    height: 45px;
    width: 150px;
    border-radius: 32.5px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    background-image: none;
    margin: auto;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    text-align: center;
    transition: 0.4s all;
}

.page-form-button-container {
    margin: 30px 0;
}

.page-form > .page-form-button-container > a, .page-form-button-container > button {
    margin: 10px;
    min-width: 150px;
}

@media screen and (max-width: 500px) {
    .page-form > .page-form-button-container > a {
        margin: 10px auto !important;
    }

    .page-form > .page-form-button-container {
        flex-direction: column;
    }
}

.cancel-button,
.cancel-button:active,
.cancel-button:hover,
.cancel-button:focus {
    background-color: #FF8A00 !important;
    border: 2px solid #FF8A00 !important;
    color: #FFF !important;
    outline: none !important;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13) !important;
}

.fa-submit-button,
.fa-submit-button:active,
.fa-submit-button:hover,
.fa-submit-button:focus {
    background-color: #3EA900 !important;
    border: 2px solid #3EA900 !important;
    color: #FFF !important;
    outline: none !important;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13) !important;
}
