.contact-us-container {
    width: 88%;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: row-reverse;
}

@media screen and (max-width: 930px) {
    .contact-us-container {
        flex-direction: column-reverse;
    }

    .footer-text {
        width: 100% !important;
        margin: auto;
        padding-bottom: 40px;
    }
}

@media screen and (max-width: 768px) {
    .contact-us-container > .footer-text {
        width: 95%;
    }

    .contact-us-container > #contact-form {
        width: 95%;
    }
}

.footer-text {
    width: 50%;
}

.footer-text > h3, .footer-text > h3 > a {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 18px;
    letter-spacing: -0.03px;
    line-height: 28px;
    text-align: left;
}

.footer-text > h3 > a {
    cursor: pointer;
    color: #FF8A00;
    display: inline;
}

#headerContact {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    max-width: 600px;
    padding-bottom:1%;
}

#headerContact > div > div > div {
    margin: auto;
}

/*#email {*/
    /*width:100%;*/
    /*max-width:400px;*/
/*}*/

#contact-form {
    width: 50%;
    max-width: 600px;
    margin: 0 auto;
}


.contact-form-without-text {
    width: 100%;
}

