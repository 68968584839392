.home-container {
    width: 88%;
    margin: auto;
}

.home-top-container {
    background-image: url(/images/imageFlashPicture@2x.png);
    background-size: 150%;
    background-repeat: no-repeat;
    height: 100%;
    min-height: 600px;
    display: flex;
    padding: 128px 10% 0;
    background-position: 100%;
}

.home-top-text-half {
    /*width: 50%;*/
}

.home-top-text-half > h1,
.home-top-text-half > h2,
.home-top-text-half > h3,
.home-top-text-half > h4,
.home-top-text-half > h5,
.home-top-text-half > h6 {
    color: #FFFFFF;
    font-family: "Open Sans";
}

.home-top-text-half > h1 {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0.06px;
    line-height: 46px;
    margin-top: 0;
    margin-bottom: 30px;
}

.home-top-text-half > h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    letter-spacing: 0.37px;
    line-height: 24px;
}

.home-top-text-half > h3 {
    font-size: 21px;
    letter-spacing: -0.11px;
    line-height: 28px;
    margin: 0;
}

.home-top-text-half > h4 {
    font-size: 16px;
    margin: 0;
    letter-spacing: -0.08px;
    line-height: 22px;
}

.home-top-tooltips-container {
    display: flex;
    margin: 50px -5px;
    flex-wrap: wrap;
}

.home-top-tooltips-container > span {
    display: block;
    text-align: center;
    height: 25px;
    width: auto;
    border-radius: 12.5px;
    background-color: #1B539B;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.5);
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.27px;
    line-height: 18px;
    padding: 3px 10px;
    margin: 5px;
    /* cursor: pointer; */
    width: 115px;
}

.home-top-links-container {
    margin-top: 40px;
    margin-bottom: 40px;
}

.home-top-links-container > h3 {
    font-size: 21px;
    color: #FFFFFF;
    font-family: "Open Sans";
    margin-top: 15px;
}

.home-top-links-container > h3 > a {
    /* color: #fff; */
    /* text-decoration: underline; */
    /* color: #ffff00; */
    color: #868680;
}

.home-top-links-container > h3 > a:hover {
    color: #23527c;
}

.home-top-form-half {
    width: 50%;
    margin: 0 0 0 25px;
    padding-bottom: 40px;
}

.home-top-form-half form {
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.5);
    padding: 24px;
    min-width: 400px;
}

.home-top-form-half form > h1,  .home-top-form-half form > h3 {
    font-family: "Open Sans";
}

.home-top-form-half form > h3 {
    font-size: 14px;
    letter-spacing: 0.29px;
    line-height: 22px;
    text-align: center;
}

.home-top-form-half form > h3 > a {
    color: #FF8A00;
}

.home-top-form-half form > h1 {
    color: #353433;
    margin: 0;
    padding-top: 0;
    padding-bottom: 16px;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: -0.11px;
    line-height: 30px;
}


.home-top-form-half .form-fa-input {
    width: 100%;
    padding-bottom: 0;
}

.home-top-form-half .form-fa-two-input-block {
    width: 100%;
}

.home-top-form-half .form-fa-two-input-block > .form-fa-input > .form-control {
    width: 100%;
}

.home-middle-main-img {
    height: 565px;
    width: 798px;
    margin: 25px auto 100px;
}

.home-middle-main-img > img:nth-child(2), .home-middle-main-img > img:nth-child(3) {
    display: none;
}

.home-middle-main-img > img {
    width: 100%;
    height: auto;
}

.home-middle-under-main-img-block {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.home-middle-under-main-img-block div {
    text-align: center;
}

.home-middle-img-container {
    height: 76px;
}

.home-middle-under-main-img-block > div {
    width: 200px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.home-middle-under-main-img-block div > h2 {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 21px;
    letter-spacing: -0.11px;
    line-height: 28px;
    text-align: center;
    margin: 0 0 10px;
}

.home-middle-under-main-img-block div > h3 {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: 0.29px;
    line-height: 17px;
    text-align: center;
    margin: 0;
}

.home-bottom-container {
    padding: 0 5%;
    margin-top: 120px;
    height: 560px;
    background-color: #F6F6F6;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.06);
}

.home-bottom-container > h1 {
    color: #353433;
    font-family: "Open Sans";
    font-size: 27px;
    font-weight: bold;
    letter-spacing: -0.14px;
    line-height: 37px;
    text-align: center;
    margin: 0;
    padding: 73px 0 85px;
}

.home-bottom-for-block {
    display: flex;
    text-align: center;
    justify-content: space-around;
}

.home-bottom-for-block > div {
    width: 300px;
}

.home-bottom-for-block div > h2 {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 21px;
    letter-spacing: -0.11px;
    line-height: 28px;
    text-align: center;
    margin-top: 5px;
}

.home-bottom-for-block div > h3 {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.29px;
    line-height: 23px;
    text-align: center;
}

.home-bottom-img-container {
    height: 66px;
    position: relative;
}

.home-bottom-img-container > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}


@media screen and (max-width: 1200px) {
    .home-container {
        width: 100%;
    }

    .adsbygoogle {
        height: 100px;
    }

    .home-top-container {
        padding: 128px 5% 0;
    }

    .home-middle-container {
        padding: 0 5% 0;
    }

}

@media screen and (max-width: 960px) {
    .home-middle-main-img {
        height: 487px;
        width: 688px;
    }

    .home-middle-main-img > img:nth-child(1) {
        display: none;
    }

    .home-middle-main-img > img:nth-child(2) {
        display: block;
    }

    .home-middle-under-main-img-block > div {
        width: 50%;
    }

    /* .home-middle-under-main-img-block > div:nth-child(1), .home-middle-under-main-img-block > div:nth-child(2) {
        margin-bottom: 74px;
    } */

    .home-middle-under-main-img-block div > h3 {
        margin: auto;
        width: 80%;
    }

    .home-bottom-for-block > div {
        width: 200px;
    }

    .home-bottom-for-block div > h2 {
        font-size: 19px;
    }

    .home-top-container {
        flex-direction: column;
        background-image: url(/images/imageFlashPicture-tablet2x.png);
    }

    .home-top-form-half {
        margin: auto;
        padding-top: 72px;
        width: 400px;
    }
}


@media screen and (max-width: 768px) {
    .home-top-container {
        background-image: url(/images/imageFlashPicture-tablet.png);
    }
    .home-middle-main-img {
        height: auto;
    }
    .home-middle-main-img {
        width: 100%;
    }

}

@media screen and (max-width: 650px) {
    .home-top-container {
        background-size: 200%;
        background-position: inherit;
    }
    .home-top-form-half {
        padding-bottom: 0;
    }
}

@media screen and (max-width: 600px) {
    .home-bottom-for-block {
        flex-wrap: wrap;
    }

    .home-bottom-for-block > div {
        width: 90%;
    }

    .home-bottom-container {
        height: 100%;
    }

    .home-bottom-container > h1, .home-bottom-for-block > div {
        padding-bottom: 48px;
    }
}

@media screen and (max-width: 500px) {
    .home-top-form-half {
        width: 100%;
    }

    .home-top-container, .home-middle-container {
        padding: 33px 5% 0;
    }

    .home-top-form-half form {
        min-width: auto;
    }
    .home-bottom-container {
        margin-top: 0;
    }
}

@media screen and (max-width: 350px) {
    .home-middle-under-main-img-block > div {
        width: 90%;
    }
}

.fa-button:active, .fa-button:focus {
    outline: none !important;
}

.fa-button {
    border-radius: 32.5px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.13);
    background-image: none;
    /*color: #fff;*/
    margin: auto;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    text-align: center;
}

.fa-button.second {
    background-color: #FF8A00;
    border: 2px solid #FF8A00;
    color: #FFF !important;
}

.fa-button.second.link-to-signup {
    height: 45px;
    width: 150px;
}

.fa-button.second:active,
.fa-button.second:hover,
.fa-button.second:focus{
    background-color: #FF8A00;
    border: 2px solid #FF8A00;
    color: #FFF !important;
}

.welcome-block {
    width: 40%;
    /* margin-left: auto; */
    transition: all 1s;
    margin-bottom: 20px;
}

.closed-welcome-container > .welcome-container {
    height: 0 !important;
    padding: 0 10% !important;
    display: none !important;
}


.closed-welcome-container > .popup-close-button {
    display: none;
}

.welcome-block-ads {
    width: 50%;
    min-width: 50%;
    /* min-height:350px; */
    /* max-width:200px; */
    max-height: 300px;
}

.welcome-block-ads-full, .welcome-block-ads-full-bottom {
    width: 100%;
    min-width: 100%;
    /* min-height:350px; */
    max-height: 300px;
}

.welcome-container {
    /* height: 400px; */
    background-color: #F6F6F6;
    padding: 20px 20px 20px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    top: 0px;
}

@media screen and (max-width: 1152px) {
    .welcome-block-ads, .welcome-block-ads-full {
        /* min-height:150px !important; */
        height: 150px !important;
        max-height: 100px !important;
    }
    .welcome-block {
        width: 80%;
        margin: auto;
    }

    .welcome-block > .link-to-signup {
        width: 100px !important;
        height: 30px !important;
        padding: initial !important;
        margin-bottom: 5px !important;
    }
    
    .welcome-block > span > h2, .welcome-block > h2 {
        font-size: 20px !important;
        margin-bottom: 5px !important;
        line-height: 1.1 !important;
    }
    .welcome-block > h4 {
        margin-top: 0px !important;
        font-size: 15px !important;
        line-height: 1.1 !important;
    }
    .welcome-container {
        padding: 10px;
    }
    .welcome-container > .popup-close-button {
        font-size: 32px !important;
    }
    .welcome-block-ads {
        width: 100%;
        min-width: 100%;
    }
}

/* @media screen and (max-width: 900px) {
    .welcome-block {
        width: 100%;
    }
    .welcome-block-ads {
        width: 100%;
        min-width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .welcome-block {
        width: 100%;
    }
    .welcome-block-ads {
        width: 100%;
        min-width: 100%;
    }
} */

@media screen and (max-width: 652px) {
    /* .welcome-container {
        padding: 40px 5%;
    } */

    .welcome-block {
        width: 100%;
    }

    .with-welcome-container > .popup-close-button {
        margin: 5px !important;
    }
    .welcome-block-ads, .welcome-block-ads-full {
        /* min-width: 200px !important; */
        /* width: 200px !important; */
        max-width: 260px !important;
        margin: auto;
        min-width: 260px !important;
        width: 260px !important;
        height: 200px !important;
        min-height: 200px !important;
        max-height: 200px !important;
    }
}

/* @media screen and (max-width: 454px) {
    .welcome-container {
        height: 300px !important;
    }
} */

.welcome-block > span > h2, .welcome-block > h2 {
    color: #353433;
    font-family: "Open Sans";
    font-size: 24px;
    letter-spacing: -0.12px;
    line-height: 33px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 0;
    cursor: pointer;
}

.welcome-block > h4 {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 18px;
    letter-spacing: -0.03px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 10px;
}

.welcome-block > ul > li > h4 {
    font-family: "Open Sans";
    font-size: 15px;
}


.with-welcome-container > .welcome-container > .popup-close-button {
    margin-bottom: 10px;
}

#iframeBlocker {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: aliceblue;
    opacity: 0.1;
}

.link-to-source {
    position: fixed;
    width: 110px;
    z-index: 1050;
    right: 20px;
    margin-top: 20px;
}

.navbar-collapse {
    z-index: 10000 !important;
}

.fa-inline-button-block {
    /* display: inline-block; */
    margin-bottom: 10px;
    text-align: center;
}

.fa-inline-button-block > h4 {
    display: inline;
    font-size: 16px;
    margin-right: 5px;
}

.fa-inline-button-block > button {
    display: inline;
    height: 20px !important;
    width: 70px !important;
    font-size: 14px;
    line-height: 0.5;
    padding: 0px;
}

.feature-ul {
    padding-left: 10%;
}

@media screen and (max-width: 652px) {
    .feature-ul {
        width: 80%;
    }
}