.streaming-container {
    padding: 25px;
    margin-bottom: 50px;
    background-color: #F6F6F6;
}

@media screen and (max-width: 820px) {
    .streaming-body {
        flex-direction: column;
    }

    .streaming-sidebar {
        width: 100% !important;
        display: flex;
        flex-wrap: wrap;
        /*justify-content: center;*/
    }

    .streaming-sidebar > .alerts-block-header-checkbox {
        width: 166px;
    }

    .streaming-content {
        margin: auto !important;
    }

}

@media screen and (max-width: 400px) {
    .streaming-container {
        padding: 5px;
    }

}

@media screen and (max-width: 620px) {
    .streaming-body {
        flex-direction: column;
    }

    .streaming-sidebar {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .streaming-sidebar > .alerts-block-header-checkbox {
        padding: 0;
        margin: 0 !important;
        width: 50%;
        justify-content: center;
    }

    .streaming-content {
        margin: auto !important;
    }

    .streaming-container {
        padding: 15px 5px;
    }

}

@media screen and (max-width: 620px) {
    .symbols-title-container {
        flex-direction: column;
    }

    .red-text, .green-text {
        text-align: center;
    }


    .auto-scroll-toggle-block {
        flex-direction: column-reverse;
    }

    .auto-scroll-toggle-block > .sms-info {
        margin: 0 !important;
    }

    .auto-scroll-toggle-block > .react-toggle {

    }
}

.streaming-body {
    display: flex;
}

.streaming-content {
    margin: 20px;
    height: 310px;
    overflow: auto;
    border: 2px solid #AAAA;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
}

.streaming-sidebar {
    width: 20%;
}

.streaming-sidebar > .alerts-block-header-checkbox > h3 {
    margin: 9px 0;
}

.streaming-sidebar > .alerts-block-header-checkbox {
    margin: 10px 0;
}

.streaming-message {
    background-color: #fff;
    border: 2px solid #AAA;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 10px;
    overflow: hidden;
}

.streaming-top-head-container {
    display: flex;
    justify-content: space-between;
}

.auto-scroll-toggle-block {
    display: flex;
    width: 20%;
}

.auto-scroll-toggle-block > .sms-info {
    margin: auto 15px;
}

.auto-scroll-toggle-block > .sms-info > h3 {
    color: #AAAAAA;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 19px;
}

.green-text {
    color: #19ab27 !important;
}

.red-text {
    color: tomato !important;
}

.streaming-top-head-container > #tickerSelect {
    min-width: auto;
    width: 80%;
}

@media screen and (max-width: 1234px) {
    .auto-scroll-toggle-block {
        width: 25%;
    }

    .streaming-top-head-container > #tickerSelect {
        width: 70%;
    }

    .streaming-sidebar > .alerts-block-header-checkbox > h3 {
        font-size: 15px;
        min-width: 111px;
    }
}