.popup-buttons-block {
    margin: 20px 0;
    display: flex;
}

.popup-buttons-block > button,
.popup-buttons-block > button:active,
.popup-buttons-block > button:hover,
.popup-buttons-block > button:focus
{
    color: #FFF !important;
    outline: none !important;
    height: 45px;
    width: 120px;
    border-radius: 32.5px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    background-image: none;
    margin: auto;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    text-align: center;
    /* margin: 5px 25px; */
    transition: 0.4s all;
}

.popup-container.popup-active > .popup-body > .popup-buttons-block > button {
    opacity: 1;
}

.popup-container > .popup-body > .popup-buttons-block > button {
    opacity: 0;
}

.popup-buttons-block > button.answer-yes-button {
    background-color: #3EA900;
}

.popup-buttons-block > button.answer-no-button {
    background-color: #FF8A00 !important;
}


.popup-buttons-block > button.answer-yes-button:active,
.popup-buttons-block > button.answer-yes-button:hover,
.popup-buttons-block > button.answer-yes-button:focus{
    background-color: #55e004;
    border: 2px solid #55e004;
    color: #FFF !important;
    outline: none !important;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13) !important;
}

.popup-buttons-block > button.answer-no-button:active,
.popup-buttons-block > button.answer-no-button:hover,
.popup-buttons-block > button.answer-no-button:focus {
    background-color: #FF8A00 !important;
    border: 2px solid #FF8A00 !important;
    color: #FFF !important;
    outline: none !important;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13) !important;
}

.question-popup-message > p {
    text-align: left;
    font-size: 15px;
}

.question-popup-message > li {
    font-size: 15px;
    text-align: left;
    padding-bottom: 10px;
}