

.alerts-button {
    height: 35px;
    width: auto;
    border: 2px solid tomato;
    border-radius: 32.5px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    color: #fff;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    background-image: none;
    margin: 10px 20px 10px 10px;

}

.alerts-button .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
}

.alerts-button-checked,
.alerts-button-checked:hover,
.alerts-button-checked:active,
.alerts-button-checked:focus{
    background-color: initial !important;
    border: 2px solid tomato !important;
    color: tomato !important;
    outline: none !important;
}

.alerts-button-unchecked,
.alerts-button-unchecked:hover,
.alerts-button-unchecked:active,
.alerts-button-unchecked:focus {
    background-color: tomato !important;
    border: 2px solid tomato !important;
    color: #fff !important;
    outline: none !important;
}

.unsubscribe-button {
    /* width: 130px !important; */
    min-width: 130px !important;
    margin-left: auto;
    margin-right: 0px;
    margin-top: 20px;
}