.popup-container {
    position: fixed;
    top: -40px;
    left: calc(50% - 165px);
    width: 332px;
    border: 1px solid #FFC580;
    border-radius: 8px;
    background-color: #FFE3C2;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.36), 0 0 4px 0 rgba(0,0,0,0.26);
    transition: all 0.5s;
    opacity: 0;
    z-index: -1;
    height: 0% !important;
}

.popup-active {
    opacity: 1;
    top: 40px;
    z-index: 1000;
    height: auto !important;
}

.big-popup-container.popup-active {
    height: 700px !important;
}

.popup-close-button {
    float: right;
    padding: 10px;
    line-height: 1;
    font-size: 20px;
    cursor: pointer;
    line-height: 10px;
    color: #777;
}

.popup-body {
    margin: 36px 25px;
    color: #353433;
    font-family: "Open Sans";
    font-size: 18px;
    letter-spacing: 0.09px;
    line-height: 26px;
    text-align: center;
}

.popup-title {
    color: #577fb5;
    margin-bottom: 20px;
    text-align: center;
}

.popup-message {

}

.popup-active-priority {
    z-index: 1500;
}

