.cookie-form-container {
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 80px;
    border: 1px solid #FFC580;
    background-color: #FFE3C2;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.36), 0 0 4px 0 rgba(0,0,0,0.26);
    z-index: 5000;
}

@media screen and (max-width: 761px) {
    .cookie-form-container > .cookie-container > .cookie-block > h4 {
        font-size: 14px !important;
    }
    
}

.cookie-form-container > .cookie-container {
    background-color: #FFE3C2;
    padding: 20px 10%;
}

.cookie-form-container > .cookie-container > .cookie-block {
    width: 100%;
}

.cookie-form-container > .cookie-container > .cookie-block > h4 {
    font-size: 16px;
    text-align: center;
}