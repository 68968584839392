.tooltip-body {
    padding: 24px;
}

.tooltip-title {
    color: rgba(27,83,155,0.8);
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.09px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 16px;
}

.tooltip-message {
    color: #353433;
    font-family: "Open Sans";
    font-size: 18px;
    letter-spacing: 0.09px;
    line-height: 26px;
    text-align: center;
    width: 284px;
}

.rc-tooltip-content > .rc-tooltip-inner {
    border: 1px solid #C3C8C9;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.36), 0 0 4px 0 rgba(0,0,0,0.26);
    padding: 0;
    margin-top: 5px;
}

.rc-tooltip-placement-bottom > .rc-tooltip-content > .rc-tooltip-arrow {
    left: 50%;
    margin-top: 5px;
}

body .rc-tooltip {
    opacity: 1;
}