.Home {
    margin: 0 auto;
}

.landerFA {
    padding-top:2%;
    padding-left:5%;
    padding-right:5%;
    text-align: center;
}

.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .lander p {
    color: #999;
}

#homePanel {
    border: 0;
}

#imgRounded {
    border-radius: 15%;
}

.label-wrapper > .label {
    display: inline-block;
    padding: .5em .6em .5em;
    width: 160px;
}