.unselect-alerts-button {
    height: 65px;
    width: 241px;
    border: 2px solid #FF8A00;
    border-radius: 32.5px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    color: #fff;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    background-image: none;
    margin: 0 20px 0 0;

}

.unselect-alerts-button-checked,
.unselect-alerts-button-checked:hover,
.unselect-alerts-button-checked:active,
.unselect-alerts-button-checked:focus{
    background-color: initial !important;
    border: 2px solid #FF8A00 !important;
    color: #FF8A00 !important;
    outline: none !important;
}

.unselect-alerts-button-unchecked,
.unselect-alerts-button-unchecked:hover,
.unselect-alerts-button-unchecked:active,
.unselect-alerts-button-unchecked:focus {
    background-color: #FF8A00 !important;
    border: 2px solid #FF8A00 !important;
    color: #fff !important;
    outline: none !important;
}