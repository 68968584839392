.fa-button-with-top-margin {
    margin-top: 30px;
}

.ResetPassword1 {
    max-width: 500px !important;
    width: 100% !important;
}

.CenterForm  {
    padding-top:2%;
    padding-left:5%;
    padding-right:5%;
    text-align: left;
    max-width:500px;
    margin: 0 auto;
}

.Subform {
    margin-bottom:55px;

}


