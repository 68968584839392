.alerts-container {
    width: 88%;
    margin: auto;
}

form {
    margin: 0 auto;
    width: 100%;
}

.top-checkboxes-container {
    display: flex;
}

.grey-block {
    padding: 80px 10%;
    background-color: #F6F6F6;
}

.alerts-grey-block-top-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.button-container-reverse > .button-reverse {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0px;
}

/* .alerts-top-left-block {
    display: flex;
    justify-content: space-between;
} */

.alerts-top-left-block > h1 {
    color: #353433;
    font-family: "Open Sans";
    font-size: 27px;
    font-weight: bold;
    letter-spacing: -0.14px;
    line-height: 37px;
    /* text-align: center; */
    margin: 0 39px 0px 0;
}

.alerts-top-left-block > .form-group > h3 {
    color: #353433;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.13px;
    line-height: 24px;
    margin: auto 23px auto 0;
}

.sms-info > h3 {
    color: #353433;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.13px;
    line-height: 15px;
    margin: 0;
}

.alerts-top-left-block > .form-group, .alerts-top-right-block > .form-group {
    display: flex;
    margin: auto;
}

.alerts-top-right-block {
    margin: auto 0;
    display: flex;
    flex-direction: column;
}

.alerts-top-right-block > .form-group {
    margin: 0;
}

.sms-info {
    margin-right: 26px;
    margin-bottom: -3px;
}

.sms-info > span {
    color: #AAAAAA;
    font-family: "Open Sans";
    font-size: 10px;
    letter-spacing: -0.14px;
    line-height: 14px;
}

.sms-info > span > i {
    background-image: url(/images/iconInfoSign.png);
    width: 14px;
    height: 14px;
    display: inline-block;
    margin-bottom: -3px;
    margin-right: 5px;
}

.alerts-grey-block-bottom-container > h4 {
    color: #AAAAAA;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 19px;
}

.symbols-title-container > h4 {
    color: #AAAAAA;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 19px;
}

.alerts-middle-container {
    padding: 60px 10%;
}


.alerts-middle-container > .container-title, .alerts-middle-container > .alerts-title-block > .container-title {
    color: #353433;
    font-family: "Open Sans";
    font-size: 27px;
    letter-spacing: -0.14px;
    line-height: 37px;
    margin: 0;
    padding-bottom: 36px;
}

.container-title > .item-warn {
    display: contents;
}

.alerts-block-header {

}

.alerts-block-header-checkbox {
    display: flex;
    margin: 15px;
}

.alerts-block-header-checkbox > h3 {
    display: inline-block;
    color: #353433;
    font-family: "Open Sans";
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.12px;
    line-height: 23px;
    margin-top: 10px;
}

.alerts-block-header > hr {
    margin: 0 -1px;
    border-radius: 3px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    border-top: 1px solid #D8DDDF;
}

.grey-block > .container-title {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 27px;
    letter-spacing: -0.14px;
    line-height: 37px;
    margin: 0;
    padding-bottom: 28px;
}

.items-container {
    border: 1px solid #D8DDDF;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    padding: 28px 20px;
    margin-bottom: 70px;
}

.item-warn {
    color: #AAAAAA;
    font-family: "Open Sans";
    font-size: 10px;
    letter-spacing: -0.14px;
    line-height: 14px;
    display: block;
}

.item-warn > i {
    height: 13px;
    width: 13px;
    background-image: url(/images/iconAlertSign.png);
    display: inline-block;
    margin-bottom: -2px;
    margin-right: 5px;
}

form span.help-block {
    color: #999;
}

#tickerSelect {
    width: 100%;
    min-width: 100%;
}

.AlertsLabel {
    font-weight: 100 !important;
    white-space: normal;
}

.subForm{
    margin: 0 auto;
    width: 100%;
    display: flex;
}

#secCheckboxes {
    text-align: left;
}

.landerFA h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.landerFA p {
    color: #999;
}

.landerFA table, tr, td {
    text-align: left;
}

.panelDiv {
    margin: 0 auto;
    width: 100%;
}

.checkbox-block {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.checkbox-block .form-group {
    /*height: 258px;*/
    width: 312px;
    border: 1px solid #D8DDDF;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    margin: 0 22px 22px 0;
    min-width: 292px;
}

.alerts-block-body {
    margin: 15px;
}

.alerts-block-body > .checkbox-container > .checkbox-label {
    font-weight: 400;
}

.alerts-block-without-body > .form-group {
    height: 70px;
}

.checkbox-block h3 {
    margin: auto 0;
}

.alerts-block-header-checkbox > .UD-title {
    word-spacing: -5px;
    line-height: 18px;
}

.checkbox-block .checkbox {
    margin-left: 15px;
}

.checkbox-block .select-all {
    margin-left: 0;
}

.checkbox-container {
    display: flex;
}

.checkbox-label {
    margin: auto 0;
    font-weight: 400;
}

.alerts-button-container {
    display: flex;
    justify-content: center;
    margin: 30px auto;
    max-width: 1000px;
}

.alerts-submit-button {
    height: 65px;
    width: auto;
    min-width: 241px;
    border-radius: 32.5px;
    background-color: #3EA900;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    margin: 0 0 0 20px;
}

@media screen and (max-width: 1200px) {
    .alerts-container {
        width: 100%;
    }

    .grey-block, .alerts-middle-container {
        padding: 40px;
    }

    /*.home-top-container, .home-middle-container {*/
        /*padding: 128px 5% 0;*/
    /*}*/

}

@media screen and (max-width: 1030px) {
    .alerts-top-left-block {
        flex-direction: column;
    }

    .alerts-top-left-block > h1 {
        text-align: left;
        margin-bottom: 45px;
    }

    .alerts-top-right-block {
        margin: auto 0 12px 0;
    }
}

@media screen and (max-width: 950px) {
    .checkbox-block .form-group {
        width: 250px;
    }

    .checkbox-block h3, .alerts-block-body > .checkbox-container > .checkbox-label {
        font-size: 14px;
    }
}

.preloadContent {
    height: 100px;
}

.align-left {
    text-align: left;
}

@media screen and (max-width: 678px) {
    .alerts-top-left-block > .form-group {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .top-checkboxes-container {
        margin-left: -10px;
    }

    .checkbox-block {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 624px) {
    .checkbox-block .form-group {
        width: 100%;
        margin: 0 0 22px 0;
    }

    .alerts-grey-block-top-container {
        flex-direction: column;
    }

    .alerts-top-left-block {
        margin-bottom: 30px;
    }

    .alerts-top-left-block > h1 {
        margin-bottom: 30px;
    }

}

@media screen and (max-width: 500px) {
    .checkbox-block-container {
        margin-bottom: 10px;
    }

    .alerts-button-container {
        flex-direction: column-reverse;
    }

    .grey-block > .button-container-reverse {
        flex-direction: column !important;
    }

    .alerts-button-container > button {
        /*padding: 0;*/
        margin: 10px auto !important;
    }

    .items-container {
        margin-bottom: 0;
    }

    .AlertsLabel {
        font-size: 1.4rem;
    }

    form label {
        font-size: 1.4rem;
    }
}

.red {
    color: tomato;
}

.bold-red {
    font-weight: bold;
    color: tomato !important;
    display: inline;
}

.symbols-checkboxes-container {
    margin-left: 15px;
    margin-top: 3px;
}
.symbols-checkboxes-container > div > label > span:nth-child(2) {
    font-size: 16px;
    margin: auto 0;
    font-weight: 400;
    margin-left: -6px;
}

.symbols-checkboxes-container > div {
    display: flex;
    flex-direction: initial;
    justify-content: space-evenly;
}

.symbols-title-container {
    display: flex;
    margin-bottom: 10px;
}

@media screen and (max-width: 410px) {
    .symbols-title-container {
        flex-direction: column;
    }
}

.fa-radio-button {
    color: #018786 !important;
}

.symbols-checkboxes-container > div > label > span:nth-child(2) > p {
    font-size: 16px;
    margin: auto 0;
    font-weight: 400;
    margin-left: -6px;
}

.symbols-checkboxes-container > .MuiButtonGroup-vertical {
    flex-direction: column;
}

.symbols-checkboxes-container > .MuiButtonGroup-vertical {
    flex-direction: column;
}

.checkbox-premium-label {
    line-height: 1.5 !important;
}

.via-checkbox-block {
    display: flex;
    margin-left: auto;
}

.checkbox-block-container {
    display: flex;
}

.items-container > .checkbox-block-container > div {
    line-height: 23px;
}

.items-container > .alerts-block-body > .checkbox-block-container > div {
    line-height: 23px;
}

.checkbox-block-container > div {
    line-height: 35px;
}

.disabled {
    opacity: 0.5;
}

.via-checkbox-block > .checkbox-container > .checkbox-label {
    font-size: 12px;
    margin-left: 3px;
    margin-right: 3px;
}

.via-checkbox-block > .checkbox-container > .fa-ui-checkbox {
    padding: 6px;
}
.via-checkbox-block > .checkbox-container > .fa-ui-checkbox > span > svg {
    width: 18px;
}

.checkbox-container > span > span.MuiIconButton-label {
    transform: scale(1.6);
}

@media screen and (max-width: 960px) {
    .checkbox-block {
        flex-wrap: wrap;
    }
    .form-group {
        margin: 22px auto auto auto;
    }
}

.empty-check-div {
    width: 54px;
    margin-right: 3px;
    margin-left: 3px;
}

.fa-ui-checkbox-color {
    color: #018786 !important;
}

.item-warn-big {
    font-size: 15px;
    line-height: 15px;
}

.alerts-title-block {
    display: flex;
    margin-bottom: 25px;
}

.alerts-title-block > h3 {
    padding-bottom: 0 !important;
    line-height: 43px !important;
}

.fa-ui-checkbox-tooltip {
    font-size: 14px !important;
    margin-bottom: 5px !important;
    /* padding-bottom: 0px !important; */
}

.alerts-top-left-block > .alerts-subs-details {
    font-size: 16px;
    margin: auto 0;
    font-weight: 400;
    text-align: start;
}

.subs-info-block a {
    color: rgb(255, 138, 0);
}

.subs-info-block a:hover {
    color: #F29746;
}

.service-button-group > button {
    background: rgba(1, 135, 135, 0.5);
    color: white !important;
    border-color: white !important;
    text-transform: none;
    font-size: 13px;
}

.service-button-group > button:hover {
    background: rgba(1, 135, 135, 0.8);
}

.active-button {
    background: #018786 !important;
}

.service-button-group > button > span > p {
    margin: 0px;
}
