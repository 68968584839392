.popup-buttons-block {
    margin: 20px 0;
}

.big-popup-container {
    left: calc(57% - 520px);
    border: 1px solid #000;
    background-color: #FFF;
    width: 900px;
    height: 700px;
    overflow-X: auto;
}


.big-popup-container.popup-active {
    top: 5px;
}

/*.big-popup-container {*/
    /*left: calc(11vw - 1%);*/
    /*height: 700px;*/
/*}*/

.big-popup-container .popup-message {
    text-align: left;
}

.big-popup-container > .popup-body > .popup-message {
    margin-bottom: 5px;
}

.big-popup-container > .popup-body > .popup-buttons-block > button {
    width: 15%;
    min-width: 100px;
}
.big-popup-container > .popup-body > .popup-buttons-block {
    margin: 10px 0;
}

.popup-container.popup-active > .popup-body > .popup-buttons-block > button {
    opacity: 1;
}

.popup-container > .popup-body > .popup-buttons-block > button {
    opacity: 0;
}

.popup-buttons-block > button.answer-yes-button {
    background-color: #3EA900;
}

.popup-buttons-block > button.answer-no-button {
    background-color: #FF8A00 !important;
}


.popup-buttons-block > button.answer-yes-button:active,
.popup-buttons-block > button.answer-yes-button:hover,
.popup-buttons-block > button.answer-yes-button:focus{
    background-color: #55e004;
    border: 2px solid #55e004;
    color: #FFF !important;
    outline: none !important;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13) !important;
}

.popup-buttons-block > button.answer-no-button:active,
.popup-buttons-block > button.answer-no-button:hover,
.popup-buttons-block > button.answer-no-button:focus {
    background-color: #FF8A00 !important;
    border: 2px solid #FF8A00 !important;
    color: #FFF !important;
    outline: none !important;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13) !important;
}

.stripe-card {
    border-radius: 5px;
    background-color: #f6f9fc;
    padding: 20px;
}

.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    min-width: 200px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

.stripe-card label {
    color: #6b7c93;
    width: 100%;
}

.stripe-card button {
    /*display: block;*/
    /*width: 100%;*/
    /*height: 37px;*/
    /*background-color: #6772e5;*/
    /*border-radius: 2px;*/
    /*font-weight: 600;*/
    /*color: #fff;*/
    /*cursor: pointer;*/
    /*width: 30%;*/
    margin: auto;
}

.stripe-card .form-fa-input, .stripe-card .form-fa-two-input-block {
    width: 100%;
    max-width: 800px;
}

.stripe-card .form-fa-two-input-block > .form-fa-input > input {
    width: 100%;
}

.split-form {
    display: flex;
    display: flex;
    max-width: 800px;
    margin: auto;

}

.split-form > label > label {
    height: 19px;
    width: 100%;
    text-align: left;
    /*color: #747474;*/
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 19px;
}

.split-form > label:nth-child(2) {
    margin-left: 10px;
}

.split-form > .form-fa-input {
    margin-left: 10px;
    margin-top: 0px;
}

.split-form > label > div {
    display: block;
    width: 100%;
    height: 44px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    margin-top: 0px;
    padding-top: 12px;
    min-width: 100%;
}

@media screen and (max-width: 950px) {
    .big-popup-container > .popup-body > .popup-message {
        font-size: 13px;
    }
    .big-popup-container > .popup-body > .symbols-checkboxes-container > div > label > span:nth-child(2) {
        font-size: 12px;
    }
    .stripe-card {
        padding: 10px;
    }

    .big-popup-container > .popup-body {
        margin: 13px 12px;
    }

    .big-popup-container {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0 !important;
    }
}

@media screen and (max-width: 800px) and (max-height: 500px) {
    .big-popup-container {
        top: 5px;
    }
}

@media screen and (max-width: 650px) {
    .card-confirm, .card-info {
        font-size: 13px;
    }
}

@media screen and (max-width: 514px) {
    .card-confirm {
        line-height: 1.5 !important;
    }
    .card-confirm > .checkbox-container > span {
        padding-top: 0;
    }
}

.card-confirm > .checkbox-container {
    display: inline-block;
}

.popup-body > .card-info {
    display: block;
    text-align: left;
}

.card-confirm {
    /* line-height: 4;
    display: flex; */
}

@media screen and (max-width: 1100px) {


}

.error {
    font-weight: bold;
    color: red;
    padding-bottom: 10px;
}