.form-fa-input {
    width: 408px;
    margin: auto;
    padding-bottom: 20px;
}

.form-fa-input > .form-control {
    min-height: 45px;
}

.form-fa-two-input-block {
    display: flex;
    width: 408px;
    margin: auto;
}

.form-fa-two-input-block > .form-fa-input {
    width: 50%;
}

.form-fa-two-input-block > .form-fa-input > .form-control {
    width: 194px;
}
.form-fa-two-input-block > .form-fa-input:nth-child(2) {
    margin-left: 10px
}

.form-fa-input > label {
    height: 19px;
    width: 100%;
    text-align: left;
    color: #747474;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 19px;
}

.form-fa-input > textarea {
    max-width: 408px;
    max-height: 91px;
    min-height: 91px;
}

.form-fa-submit {
    height: 45px;
    width: 150px;
    border-radius: 32.5px;
    background-color: #3EA900;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
    background-image: none;
    color: #fff;
    margin: auto;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.29px;
    line-height: 19px;
    text-align: center;
}

.form-fa-submit:active,
.form-fa-submit:hover,
.form-fa-submit:focus{
    background-color: #55e004;
    border: 2px solid #55e004;
    color: #FFF !important;
    outline: none !important;
}

.sign-form-fa-submit {
    height: 65px;
    width: 241px;
}

.sign-form-fa-submit:hover {
    background-color: #55e004;
    color: #fff;
}

@media screen and (max-width: 500px) {
    .form-fa-two-input-block {
        flex-direction: column;
        width: 100%;
    }

    form > .form-fa-input, .form-fa-two-input-block > .form-fa-input {
        width: 100%;
        margin-left: 0 !important;
    }

    form .form-fa-input > .form-control, form .form-fa-two-input-block > .form-fa-input > .form-control {
        width: 100%;
    }
}