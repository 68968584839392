.header {
    background: none;
    border: none;
    height: 55px;
    box-shadow: none;
    display: flex;
    align-items: flex-end;
    margin: 0;
}

.right-nav-container {

}

.sign-nav-container {
    width: 174px;
    display: flex;
    margin-left: 5px;
    display: flex;
    justify-content: space-around;
}

.social-nav-container {
    width: 160px;
    display: flex;
    justify-content: space-around;
}

@media (min-width: 1200px) {
    .container {
        width: 91.5%;
    }
}

@media screen and (max-width: 1200px){
    .header > .container {
        width: 90%;
        padding: 0;
    }
}

 @media screen and (min-width: 768px) and (max-width: 992px) {
    .header {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 992px) {

    .right-nav-container {
        display: flex;
        flex-direction: column-reverse;
    }

    .social-nav-container {
        margin-left: 15px;
    }
    .header > .container > .navbar-collapse {
        position: absolute;
        right: 5%;
    }
}

@media screen and (max-width: 768px) {
    .navbar-toggle.collapsed:after, .navbar-toggle.collapsed:before{
        opacity: 0;
        transition: 0.4s all;
    }

    .navbar-toggle:after, .navbar-toggle:before {
        transition: 0.4s all;
        opacity: 1;
    }

    .navbar-toggle:after, .navbar-toggle:before {
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        z-index: 1100;
        top: 49%;
        right: 7%;
        opacity: 1;
    }

    .navbar-toggle:after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: #fff;
        border-width: 22px;
        top: 55%;
    }

    .navbar-toggle:before {
        border-color: rgba(194, 225, 245, 0);
        border-bottom-color: #C3C8C9;
        border-width: 22px;
    }
    .header > .container > .navbar-collapse {
        top: 128%;
        z-index: 1000;
        background-color: #fff;
        border: 1px solid #C3C8C9;
        border-radius: 8px;
        box-shadow: 0 4px 16px 0 rgba(0,0,0,0.36), 0 0 4px 0 rgba(0,0,0,0.26);
        width: 250px;

    }

    .row > .navbar-default .navbar-nav > li > a, .row > .navbar-default .navbar-nav > li > a > a {
        font-size: 20px;

    }

    .nav-header > .navbar-toggle {
        margin: 5px;
    }

    .header > .container {
        width: 90%;
    }

    .nav-links {
        padding: 0 15px;
    }

    .nav-links > li {
        border-bottom: 1px solid #DDE1E4;
    }

    .right-nav-container {
        padding: 0 15px;
    }

    .right-nav-container > ul {
        border-bottom: 1px solid #DDE1E4;
        margin: 0;
        width: auto;
    }
    .row > .navbar-default .navbar-nav > li.active-sign {
        margin: 5px;
    }
}

.navbar-default .navbar-nav > li.active-sign {
    color: #fff !important;
    border-radius: 3px;
    background-color: #FF8A00;
    /*height: 25px;*/
    width: 74px;
    margin: 10px;
}

.navbar-default .navbar-nav > li > a, .navbar-default .navbar-nav > li > a > a {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
}

.navbar-default .navbar-nav > li.active-sign > a, .navbar-default .navbar-nav > li.active-sign > a > a {
    color: #FFFFFF;
    padding: 0;
    margin: 0;
    text-align: center;
    line-height: 1.7;
}

.navbar-default .navbar-nav > li.twitter-nav > a {
    padding-top: 10px;
}

.navbar-default .navbar-nav > li > a > a:focus, .navbar-default .navbar-nav > li > a > a:hover {
    /*color: #333;*/
    background-color: transparent;
    text-decoration: none;
}

.navbar-default .navbar-nav > li.stocktwits-nav > a {
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 0;
}

.navbar-default .navbar-nav > li > a > a {
    color: #777;
}

.container > .nav-header > a, .container > .nav-header > a > a {
    line-height: 0.6;
    color: #FF8A00;
    font-family: "Open Sans";
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.55px;
    padding-top: 5px;
}

.container > .nav-header > a:hover, .container > .nav-header > a > a:hover {
    color: #FF8A00;
}

.container > .nav-header > a > img {
    display: inline-block;
    margin-right: 5px;
    margin-top: -5px;
}

.header-text {
    display: inline;
    font-size: 16px;
    margin: 0;
    padding: 0;
    padding-top: 10px;
    text-align: center;
    cursor: pointer;
}

@media screen and (max-width: 550px){
    .nav-fa-block {
        display: inline-flex;
        justify-content: space-between;
    }
}

.header-tooltip-block > p {
    font-size: 14px;
    line-height: 1.5;
}

.shorter-tooltip {
    padding: 5px !important;
}