.Footer {
    padding-top: 100px;
    text-align: center;
    margin: 0 auto;
    color: #54504B;
    font-family: "Open Sans";
    font-size: 18px;
}

.lander {
    margin-bottom: 25px;
}

.footer-bottom {
    border-top: 1px solid #DDE1E4;
    margin-top: 30px;
    padding-top: 30px;
}

.footer-bottom > h1 {
    color: #54504B;
    font-family: "Open Sans";
    font-size: 27px;
    letter-spacing: -0.14px;
    line-height: 37px;
    text-align: center;
    width: 90%;
    margin: 0 auto 40px;
}

.footer-bottom-links {
    box-shadow: none;
    background: none;
    border: none;
}

.footer-bottom-links > .container > h4 {
    width: 50%;
    color: #AAAAAA;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.01px;
    line-height: 19px;
    float: right;
    text-align: right;
}

.footer-bottom-links > .container {
    width: auto;
    padding: 0 5%;
}

@media screen and (max-width: 800px) {
    .footer-bottom-links > .container > h4 {
        width: 100%;
    }

    .Footer {
        padding-top: 48px;
    }

    .footer-bottom-links > .container > .navbar-nav {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin: 0;
    }

    .footer-bottom-links > .container > h4 {
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    .footer-bottom-links > .container > .navbar-nav > li > a {
        padding: 10px 0;
    }
}