/*.landerFA form {*/
    /*margin: 0 auto;*/
    /*max-width: 800px;*/
    /*width:100%;*/
/*}*/


.landerFA {
    padding-top:2%;
    padding-left:5%;
    padding-right:5%;
    text-align: center;
}


#centralAlert {
    margin: 0 auto;
    max-width: 320px;
}

.loginDiv {
    margin: 0 auto;
    max-width: 400px;
    width:100%;
}

.underform-link {
    margin: 30px 0px;
    height: 100%;
    display: block;
}

.underform-link:hover {
    text-decoration: none;
}

.header-form-alerts {
    color: #999;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

.signin-reset-password-text-block {
    margin: 32px 0 20px;
}

.signin-reset-password-text-block > p, .signin-reset-password-text-block > a {
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.29px;
    line-height: 22px;
    text-align: center;
    margin: 0;
}

.signin-reset-password-text-block > a {
    color: #FF8A00;
}