.landerFA form {
        margin: 0 auto;
    }

.landerFA {
    padding-top:2%;
    padding-left:5%;
    padding-right:5%;
    text-align: center;
}

.landerFA form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
}

.panel, .panel-body {
    max-width: 800px;
    text-align: center;
    margin: auto;
}

#centerDiv {
    margin: 0 auto;
    max-width:400px;
    width:100%;
}